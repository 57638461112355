// import "src/assets/Profile.scss"
import profileimage from "src/assets/images/profile.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faXTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { useEffect, useState } from "react";
import "src/assets/sassStyles/_profile.scss";
import LoadingSpinner from "src/components/common/LoadingSpinner";
import { Modal, Button, Form } from "react-bootstrap";
import PractitionerProfile from "./PractitionerProfile";
import { ToastContainer, toast } from "react-toastify";

const PractitionerDirectoryCard = ({
  profile,
  isCurrentProfile = false,
  isCurrentProfilePublished = true,
}) => {
  const [lgShow, setLgShow] = useState(false);
  const [openedProfile, setOpenedProfile] = useState([]);
  const [profileData, setProfileData] = useState({
    counselorId: 0,
    firstname: "",
    lastname: "",
    showPhone: false,
    showEmail: false,
    showAddress: true,
    phone: "",
    address: "",
    email: "",
    stateprovince: "",
    zip: "",
    country: "India",
    ages_seen: [],
    area_expertise: [],
    languagespoken: [],
    counselingformat: [],
    therapeuticApproaches: [],
    counselorFormat: [],
    license_degree: "",
    aboutmebio: "",
    feesInsurance: "",
    religiousAffiliation: [],
    otherexpertise: "",
    experience: "",
    profileimage: null,
    isImageString: true,
    clientfocus: [],
    twitterURL: "",
    linkedinURL: "",
    facebookURL: "",
    websiteURL: "",
    // email: "",
    // password: "",
  });
  useEffect(() => {
    //   //   setisLoading(true);
    console.log("oped");
  }, []);
  const handleCopy = (infoToCopy) => {
    navigator.clipboard
      .writeText(infoToCopy)
      .then(() => {
        notify("Info copied!", true);
      })
      .catch((error) => {
        console.error("Unable to copy:", error);
      });
  };
  const notify = (message, IsSuccess) => {
    IsSuccess
      ? toast.success(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined, // or customize the progress bar
          //  onClick: () => alert("Notification clicked!"),
          onClose: () => console.log("Notification closed!"),
        })
      : toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined, // or customize the progress bar
        });
  };
  const handleProfileView = (practitioner) => {
    console.log(practitioner);

    setProfileData({
      ...profileData,
      counselorId: practitioner.CustomerId,
      firstname: practitioner.FirstName,
      lastname: practitioner.LastName,
      showPhone: practitioner.ShowPhone,
      showEmail: practitioner.ShowEmail,
      showAddress: practitioner.ShowAddress,
      phone: practitioner.PhoneNumber,
      address: practitioner.Address,
      email: practitioner.Email,
      stateprovince: practitioner.StateProvince,
      zip: practitioner.Zipcode,
      country: practitioner.Country,
      ages_seen:
        (practitioner.AgesSeen &&
          practitioner.AgesSeen.split(",").map((option) => ({
            value: option,
            label: option,
          }))) ||
        [],
      area_expertise:
        (practitioner.AreaExpertise &&
          practitioner.AreaExpertise.split(",").map((option) => ({
            value: option,
            label: option,
          }))) ||
        [],
      languagespoken:
        (practitioner.LanguagesSpoken &&
          practitioner.LanguagesSpoken.split(",").map((option) => ({
            value: option,
            label: option,
          }))) ||
        [],
      counselingformat:
        (practitioner.CounselingFormat &&
          practitioner.CounselingFormat.split(",").map((option) => ({
            value: option,
            label: option,
          }))) ||
        [],
      therapeuticApproaches:
        (practitioner.TherapeuticApproaches &&
          practitioner.TherapeuticApproaches.split(",").map((option) => ({
            value: option,
            label: option,
          }))) ||
        [],
      counselorFormat:
        (practitioner.CounselorFormat &&
          practitioner.CounselorFormat.split(",").map((option) => ({
            value: option,
            label: option,
          }))) ||
        [],
      license_degree:
        (practitioner.LicenseDegreeType &&
          practitioner.LicenseDegreeType.split(",").map((option) => ({
            value: option,
            label: option,
          }))) ||
        [],
      aboutmebio: practitioner.aboutmebio,
      feesInsurance: practitioner.FeesInsurance,
      religiousAffiliation:
        (practitioner.ReligiousAffiliation &&
          practitioner.ReligiousAffiliation.split(",").map((option) => ({
            value: option,
            label: option,
          }))) ||
        [],
      otherexpertise: "",
      experience: practitioner.Experience,
      profileimage: `data:image/png;base64,${practitioner.Profileimage}`,
      isImageString: true,
      clientfocus:
        (practitioner.ClientFocus &&
          practitioner.ClientFocus.split(",").map((option) => ({
            value: option,
            label: option,
          }))) ||
        [],
      twitterURL: practitioner.TwitterURL,
      linkedinURL: practitioner.LinkedinURL,
      facebookURL: practitioner.facebookURL,
      websiteURL: practitioner.websiteURL,
    });
    setOpenedProfile(practitioner);
    setLgShow(true);
  };
  const cropString = (str, maxLength) => {
    if (str.length <= maxLength) {
      return str;
    } else {
      return str.substring(0, maxLength) + "..."; // Append ellipsis for indication
    }
  };
  function getTextFromHTML(htmlContent) {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlContent;
    let plainText = tempElement.textContent || tempElement.innerText || "";
    // Replace multiple consecutive whitespace characters with a single space
    plainText = plainText.replace(/\s+/g, " ");
    return plainText;
  }
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    return `${month}, ${year}`;
  };

  return (
    <>
      <div key={profile.id} className="col-sm-4 card-col mb-4">
        <div className="card p-4">
          {isCurrentProfile ? (
            <div className=" position-relative">
              <span className="badge bg-primary text-white position-absolute top-0 end-0">
                ME
              </span>
            </div>
          ) : (
            <></>
          )}
          <div className=" image d-flex flex-column justify-content-center align-items-center">
            {/* <button
              className="card-image-btn  btn-secondary"
              style={{
                backgroundImage:
                  "url(`data:image/png;base64,${profile.Profileimage}`)",
                backgroundSize: "cover",
                "background-repeat": "no-repeat",
              }}
            >
              {profile.Profileimage ? (
                <img
                  src={`data:image/png;base64,${profile.Profileimage}`}
                  className="card-image prac"
                />
              ) : (
                ""
              )}
            </button> */}
            <label className="custom-file-upload fas">
              <div className="img-wrap">
                <img
                  for="photo-upload"
                  src={`data:image/png;base64,${profile.Profileimage}`}
                />
              </div>
            </label>
            <span className="name">
              {profile.FirstName + " " + profile.LastName}
            </span>

            <div className="d-flex flex-row justify-content-center align-items-center gap-2 mb-2">
              <span className="idd1">
                {profile.ShowAddress ? <>{profile.Address}, </> : null}
                {profile.StateProvince}
                {", "}
                {profile.Country}{" "}
                {/* {profile.ShowAddress ? (
                  <button
                    title="Click to copy address."
                    className="border-0 focus-visible"
                    onClick={() => handleCopy(profile.Address)}
                  >
                    <i className="fa fa-copy"></i>
                  </button>
                ) : null} */}
              </span>
              <span className="idd"></span>
            </div>
            {/* <div className="d-flex flex-row justify-content-center align-items-center mt-3">
        <span className="number">
          1069 <span className="follow">Followers</span>
        </span>
      </div> */}
            <div className=" d-flex mt-2" style={{ width: "50%" }}>
              <button
                onClick={() => handleProfileView(profile)}
                className="btn1 btn-dark"
                style={{ width: "100%" }}
              >
                View
              </button>
            </div>
            <div className="text mt-3">
              <p
                className="card-bio"
                dangerouslySetInnerHTML={{
                  __html: cropString(
                    decodeURIComponent(profile.aboutmebio),
                    200
                  ),
                }}
              />
            </div>
            <div className="gap-3 mt-3 icons d-flex flex-row justify-content-center align-items-center card-skills">
              <section className="resume-skills-section-card ">
                <ul className="list-inline text-uppercase mb-2">
                  {profile.CounselorFormat.split(",")
                    .slice(0, 3)
                    .filter((item) => item !== "Other (please specify in bio)")
                    .map((label) => (
                      <li className="list-inline-item">
                        <span
                          className="badge resume-skill-badge"
                          style={{
                            background: "rgba(13, 92, 77, 0.7215686275)",
                            color: "#fff",
                            fontSize: "0.7rem",
                          }}
                        >
                          {label}
                        </span>
                      </li>
                    ))}
                  {profile.CounselorFormat.split(",").length > 3 ? (
                    <div className=" d-inline text-lowercase">more...</div>
                  ) : (
                    <></>
                  )}
                </ul>
              </section>
            </div>
            {isCurrentProfile && isCurrentProfile.isPublished === false ? (
              <div className=" px-2 rounded mt-2 date ">
                <span className="not-join">Approval Pending</span>
              </div>
            ) : (
              <></>
            )}
          </div>
          {/* {isCurrentProfile ? (
            <i className="text-muted d-flex justify-content-center align-items-center not-join">
              Note:You are viewing this becaue you have loggedin.
            </i>
          ) : null} */}
        </div>
      </div>
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <PractitionerProfile practitionerProfile={profileData} />
        </Modal.Body>
      </Modal>
    </>
  );
};
export default PractitionerDirectoryCard;
