
const LoadingSpinner=({ info})=> {
	
    
return (
  <>  <div id="loadercus" class="contentcus" style={{display:'flex'}}>
  <div class="loading">
<p>loading</p>
     <span></span>
  </div>
</div>
     <div className="loading-info">{info}</div>
     </>

);
}
export default LoadingSpinner;