// ordersSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orders: [],
  loading: false,
  error: null,
};

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    addOrder: (state, action) => {
      state.orders.push(action.payload);
    },
    setLoadingOrders: (state, action) => {
      state.loading = action.payload;
    },
    setErrorOrders: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { addOrder, setLoadingOrders, setErrorOrders } =
  ordersSlice.actions;
export default ordersSlice.reducer;
