import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../header/Header";
import {
  handleTrainingLinkLogin,
  handleCommonLinkLogin,
} from "../../API/apiService";
import LoadingSpinner from "../common/LoadingSpinner";
import { useDispatch } from "react-redux";
import { updateCustomer } from "src/redux/customerSlice";
export default function LinkLogin() {
  const { authguid, authtype } = useParams();
  const [isLoading, setisLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    console.log("useffect");
    console.log(authguid, authtype);

    fetchData();
  }, []);
  async function fetchData() {
    setisLoading(true);
    // You can await here
    let result = await handleCommonLinkLogin(authguid, authtype);
    debugger;
    setisLoading(false);
    await dispatch(updateCustomer(result.data));

    sessionStorage.setItem("customer", JSON.stringify(result.data));
    navigate("/Practitioner/PractitionerDirectory");
  }
  return (
    <>
      <Header />
      {isLoading ? (
        <LoadingSpinner info="Validating your login..."></LoadingSpinner>
      ) : (
        <></>
      )}
    </>
  );
}
