import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import tg_logo from "src/assets/images/tg-logo.png";
// import "http://fonts.googleapis.com/css?family=Lato:300,400,400italic,600,700|Raleway:300,400,500,600,700|Crete+Round:400italic";
// import "src/assets/css/bootstrap.css";
import "src/assets/sassStyles/style.scss";
import "src/assets/css/swiper.css";
import "src/assets/css/dark.css";
import "src/assets/css/font-icons.css";
import "src/assets/css/animate.css";
import "src/assets/css/magnific-popup.css";
import { faUser, faUserAstronaut } from "@fortawesome/free-solid-svg-icons";
import "src/assets/css/responsive.css";
import { Navbar, Nav, Dropdown, Container } from "react-bootstrap";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAPIURL } from "src/API/apiService";
export default function Header({ currentPage }) {
  const [currentCustomer, setcurrentCustomer] = useState(null);
  const { REACT_APP_TJTAURL, REACT_APP_CounselorLogout } = process.env;
  const navigate = useNavigate();

  const displayMsg = () => {
    toast.success("Success Notification !", {
      position: toast.POSITION.TOP_CENTER,
    });
    // toast(Msg) would also work
  };
  const emptyonclick = () => {};
  const handleLogout = async () => {
    try {
      // Call the logout endpoint
      let Logouturl = getAPIURL(REACT_APP_CounselorLogout);

      const response = await fetch(Logouturl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Assuming your JWT token is stored in sessionStorage
          Authorization: `Bearer ${currentCustomer.AuthToken}`,
        },
      });

      if (response.ok) {
        // Logout successful, remove token from sessionStorage
        sessionStorage.removeItem("customer");
        // Redirect or perform any other action upon successful logout
        // Example: Redirect to login page
        window.location.href = "/Practitioner/PractitionerDirectory";
      } else {
        // Handle logout failure
        console.error("Logout failed");
      }
    } catch (error) {
      // Handle fetch error
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    debugger;
    var sessioncustomer = sessionStorage.getItem("customer");
    if (sessioncustomer) {
      const customer = JSON.parse(sessioncustomer);
      setcurrentCustomer(customer);
    }
  }, []);
  return (
    <>
      <header id="header" className="">
        <div>
          <Navbar
            id="header-wrap"
            className="bg-body-tertiary header-base-style"
          >
            <Container>
              <Navbar.Brand href="#home">
                {" "}
                <div id="logo">
                  <a
                    href={REACT_APP_TJTAURL}
                    className="standard-logo"
                    data-dark-logo={tg_logo}
                  >
                    <img src={tg_logo} alt="tjta Logo" />
                  </a>
                  <a
                    href="index.html"
                    className="retina-logo"
                    data-dark-logo={tg_logo}
                  >
                    <img src={tg_logo} alt="tjta Logo" />
                  </a>
                </div>
              </Navbar.Brand>
              <Navbar.Toggle />
              <Nav className="me-auto">
                {!currentCustomer && (
                  <Nav.Link href={REACT_APP_TJTAURL}>T-JTA Home</Nav.Link>
                )}
                <Nav.Link
                  active={currentPage === "practitionerdirectory"}
                  href="/Practitioner/PractitionerDirectory"
                >
                  Practitioner Directory
                </Nav.Link>
                {currentCustomer && (
                  <Nav.Link href={REACT_APP_TJTAURL + "Counselor/Home.aspx"}>
                    Counselor Dashboard
                  </Nav.Link>
                )}
                <Nav.Link href={REACT_APP_TJTAURL + "contactus"}>
                  Contact Us
                </Nav.Link>
              </Nav>
              <Navbar.Collapse className="justify-content-end">
                <Navbar.Text>
                  {currentCustomer ? (
                    <>
                      <FontAwesomeIcon icon={faUser} />
                      {currentCustomer.Firstname}
                      {" | "}
                      <a href="#" onClick={handleLogout}>
                        Logout
                      </a>
                    </>
                  ) : (
                    <a href="#" onClick={emptyonclick}>
                      <FontAwesomeIcon icon={faUserAstronaut} /> Guest
                    </a>
                  )}
                </Navbar.Text>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </header>
      {/* #header end  */}
    </>
  );
}
