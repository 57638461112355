// import "src/assets/Profile.scss"
import profileimage from "src/assets/images/profile.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer, toast } from "react-toastify";
import {
  faGlobe,
  faPhoneVolume,
  faEnvelope,
  faLocationDot,
  faIdBadge,
  faChildren,
  faPersonDotsFromLine,
  faPuzzlePiece,
  faSuitcaseMedical,
  faFileInvoiceDollar,
  faPeopleRoof,
  faMicrophoneLines,
  faAward,
  faSpa,
  faToriiGate,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faXTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { useEffect, useState } from "react";
import "src/assets/sassStyles/_profile.scss";
import LoadingSpinner from "src/components/common/LoadingSpinner";
import { Modal, Button, Form, Alert } from "react-bootstrap";
import { getAPIURL } from "src/API/apiService";
const PractitionerProfile = ({ practitionerProfile }) => {
  const [isLoading, setisLoading] = useState(false);
  const [isOTPSending, setisOTPSending] = useState(false);
  const [isContactSubmit, setisContactSubmit] = useState(false);
  // const { practitionerProfile } = practitioner;
  const [show, setShow] = useState(false);
  const initialCOntactdata = {
    fullName: "",
    email: "",
    request: "",
    otp: "",
    address: "",
    requestFor: 0,
  };
  const [contactData, setcontactData] = useState(initialCOntactdata);
  const [otp, setOTP] = useState("");
  const [isOTPVerified, setIsOTPVerified] = useState(false);
  const [otpSent, setOTPSent] = useState(false);
  const [otpSendError, setOTPSendError] = useState(false);
  const [otpResponse, setotpResponser] = useState([]);
  const { REACT_APP_SendOTP, REACT_APP_PractitionerContactRequest } =
    process.env;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleContactChange = (e) => {
    const { name, value } = e.target;
    setcontactData((prevcontactData) => ({
      ...prevcontactData,
      [name]: value,
    }));
  };
  const notify = (message, IsSuccess) => {
    IsSuccess
      ? toast.success(message, {
          position: "top-right",
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined, // or customize the progress bar
          // onClick: () => alert("Notification clicked!"),
          onClose: () => console.log("Notification closed!"),
        })
      : toast.error(message, {
          position: "top-right",
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined, // or customize the progress bar
        });
  };
  const handleSendOTP = async (e) => {
    e.preventDefault();

    try {
      // Send email with OTP
      var sendotpURL = getAPIURL(REACT_APP_SendOTP);
      debugger;
      var Email = contactData.email.toString();
      setisOTPSending(true);
      fetch(sendotpURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ Email }),
      })
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          setisOTPSending(false);
          console.log(result);
          if (result.data > 0) {
            setOTPSent(true);
            setOTPSendError(false);
            setotpResponser(result);
            setcontactData((prevcontactData) => ({
              ...prevcontactData,
              request: result.data,
              requestFor: practitionerProfile.counselorId,
            }));
            console.log("Code sent to:", Email);
          } else {
            setOTPSent(false);
            setOTPSendError(true);
            console.error("Failed to send Code");
          }

          if (
            result.data === null ||
            result.data.length === 0 ||
            result.data === 0
          ) {
            notify("Failed to send Code", false);
          }
          //console.log("practitioner", result);
        })
        .catch((error) => {
          setisOTPSending(false);
          console.error("Error fetching data:", error);
        });
    } catch (error) {
      setisOTPSending(false);
      setOTPSent(false);
      setOTPSendError(true);
      console.error("Error sending OTP:", error);
    }
  };
  const validateEmail = (email) => {
    // Simple email validation
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const validateUsername = (username) => {
    // Simple username validation
    return username.trim().length > 0;
  };

  const handleContactSubmit = async (e) => {
    e.preventDefault();
    if (
      !validateEmail(contactData.email) ||
      !validateUsername(contactData.fullName)
    ) {
      notify("Please enter a valid email and username.", false);
      return;
    }
    console.log(contactData.otp);
    if (contactData.otp === undefined || contactData.otp === "") {
      notify(
        "Attention: Please enter your verification code to submit this form.",
        false
      );
      return;
    }
    try {
      setisContactSubmit(true);
      // Send email with OTP
      var sendotpURL = getAPIURL(REACT_APP_PractitionerContactRequest);
      debugger;
      fetch(sendotpURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(contactData),
      })
        .then((response) => {
          setisContactSubmit(false);
          if (response.ok) {
            console.log(response);
          } else {
            console.error("Failed to submit contact request.");
            setcontactData(initialCOntactdata);
            setOTPSent(false);
            setOTPSendError(true);
            handleClose();
          }
          return response.json();
        })
        .then((result) => {
          debugger;
          if (result.message === "Success") {
            notify(
              "Your request submitted successfully. We will let the practitioner know about your interest.",
              true
            );
            setcontactData(initialCOntactdata);
            setOTPSent(false);
            setOTPSendError(true);
            handleClose();
          } else {
            notify(result.message, false);
          }
          //console.log("practitioner", result);
        })
        .catch((error) => {
          setisContactSubmit(false);
          console.error("Error fetching data:", error);
        });
    } catch (error) {
      setisContactSubmit(false);
      console.error("Error sending Request:", error);
    }
  };
  useEffect(() => {
    //   setisLoading(true);
    console.log("oped", practitionerProfile);
  }, [practitionerProfile]);
  return isLoading ? (
    <LoadingSpinner info="Getting practitioners.."></LoadingSpinner>
  ) : (
    <>
      <body class="light-mode">
        <div>
          <div className="main-content-wrapper">
            <div className="container-fluid">
              <div className="top-bar text-center position-relative">
                <div className="top-bar-inner">
                  <a
                    className="btn btn-primary top-bar-cta"
                    onClick={handleShow}
                  >
                    Contact Me
                  </a>

                  <ul className="social-list list-inline mx-auto">
                    {practitionerProfile.facebookURL != "" &&
                    practitionerProfile.facebookURL.trim() != "" ? (
                      <li className="list-inline-item me-0 me-md-1 me-lg-2">
                        <a href={practitionerProfile.facebookURL}>
                          <FontAwesomeIcon icon={faFacebook} />
                        </a>
                      </li>
                    ) : null}
                    {practitionerProfile.linkedinURL != "" &&
                    practitionerProfile.linkedinURL.trim() != "" ? (
                      <li className="list-inline-item me-0 me-md-1 me-lg-2">
                        <a href={practitionerProfile.linkedinURL}>
                          <FontAwesomeIcon icon={faLinkedin} />
                        </a>
                      </li>
                    ) : null}
                    {practitionerProfile.twitterURL != "" &&
                    practitionerProfile.twitterURL.trim() != "" ? (
                      <li className="list-inline-item me-0 me-md-1 me-lg-2">
                        <a href={practitionerProfile.twitterURL}>
                          <FontAwesomeIcon icon={faXTwitter} />
                        </a>
                      </li>
                    ) : null}
                    {practitionerProfile.websiteURL != "" &&
                    practitionerProfile.websiteURL.trim() != "" ? (
                      <li className="list-inline-item me-0 me-md-1 me-lg-2">
                        <a href={practitionerProfile.websiteURL}>
                          <FontAwesomeIcon icon={faGlobe} />
                        </a>
                      </li>
                    ) : null}
                  </ul>
                </div>
              </div>

              <div className="resume-wrapper mx-auto rounded-2">
                <div className="resume-header px-4 px-lg-5">
                  <div className="resume-profile-holder text-center">
                    {practitionerProfile.isImageString ? (
                      <label className="custom-file-upload-profile fas">
                        <div className="img-wrap">
                          <img
                            for="photo-upload"
                            src={practitionerProfile.profileimage}
                          />
                        </div>
                      </label>
                    ) : (
                      // <img
                      //   className="resume-profile-pic rounded-circle"
                      //   src={practitionerProfile.profileimage}
                      // />
                      <label className="custom-file-upload-profile fas">
                        <div className="img-wrap">
                          <img
                            for="photo-upload"
                            src={
                              practitionerProfile.profileimage
                                ? URL.createObjectURL(
                                    practitionerProfile.profileimage
                                  )
                                : profileimage || ""
                            }
                          />
                        </div>
                      </label>
                      // <img
                      //   className="resume-profile-pic rounded-circle"
                      //   src={
                      //     practitionerProfile.profileimage
                      //       ? URL.createObjectURL(
                      //           practitionerProfile.profileimage
                      //         )
                      //       : profileimage || ""
                      //   }
                      // />
                    )}

                    <h2 className="resume-name text-uppercase">
                      {practitionerProfile.firstname +
                        " " +
                        practitionerProfile.lastname}
                    </h2>
                    <div className="list-inline-item text-uppercase">
                      {practitionerProfile.counselorFormat
                        .filter(
                          (item) =>
                            item.label !== "Other (please specify in bio)"
                        )
                        .map((option) => option.label)
                        .join(" | ")}
                    </div>
                    <div className="resume-contact mt-4">
                      <ul className="resume-contact-list list-unstyled list-inline mb-0 justify-content-between">
                        <li className="list-inline-item me-md-3 me-lg-5">
                          <FontAwesomeIcon icon={faPhoneVolume} />{" "}
                          {practitionerProfile.showPhone
                            ? practitionerProfile.phone
                            : "Hidden"}
                        </li>
                        <li className="list-inline-item me-md-3 me-lg-5">
                          <FontAwesomeIcon icon={faEnvelope} />{" "}
                          {practitionerProfile.showEmail
                            ? practitionerProfile.email
                            : "Hidden"}
                        </li>
                        <li className="list-inline-item me-lg-5">
                          <FontAwesomeIcon icon={faLocationDot} />{" "}
                          {practitionerProfile.showAddress
                            ? practitionerProfile.address
                            : "Hidden"}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="resume-body p-4 p-lg-5">
                  <div className="row">
                    <div className="col-main col-12 col-lg-8 pe-lg-4">
                      <section className="resume-summary-section resume-section">
                        <h3 className="resume-section-heading text-uppercase py-lg-3 py-lg-3">
                          <i className="resume-section-heading-icon me-2">
                            <FontAwesomeIcon icon={faIdBadge} />
                          </i>
                          ABOUT Me/BIO
                        </h3>
                        <div
                          className="resume-summary-desc"
                          dangerouslySetInnerHTML={{
                            __html: decodeURIComponent(
                              practitionerProfile.aboutmebio
                            ),
                          }}
                        />
                      </section>

                      <hr />
                      <section className="resume-summary-section resume-section">
                        <h3 className="resume-section-heading text-uppercase py-lg-3 py-lg-3">
                          <i className="resume-section-heading-icon me-2">
                            <FontAwesomeIcon icon={faPersonDotsFromLine} />
                          </i>
                          EXPERIENCE
                        </h3>
                        <div
                          className="resume-summary-desc"
                          dangerouslySetInnerHTML={{
                            __html: decodeURIComponent(
                              practitionerProfile.experience
                            ),
                          }}
                        />
                      </section>

                      <hr />
                      <section className="resume-summary-section resume-section">
                        <h3 className="resume-section-heading text-uppercase py-lg-3 py-lg-3">
                          <i className="resume-section-heading-icon me-2">
                            <FontAwesomeIcon icon={faFileInvoiceDollar} />
                          </i>
                          FEES & INSURANCE
                        </h3>
                        <div
                          className="resume-summary-desc"
                          dangerouslySetInnerHTML={{
                            __html: decodeURIComponent(
                              practitionerProfile.feesInsurance
                            ),
                          }}
                        />
                      </section>
                    </div>
                    <div className="col-12 col-lg-4 ps-lg-4">
                      <section className="resume-skills-section resume-section">
                        <h3 className="resume-section-heading text-uppercase ">
                          <i className="resume-section-heading-icon me-2">
                            <FontAwesomeIcon icon={faChildren} />
                          </i>
                          Client Focus
                        </h3>
                        <ul className="list-inline">
                          {practitionerProfile.clientfocus.map(
                            ({ label, value }) => (
                              <li className="list-inline-item">
                                <span className="badge resume-skill-badge">
                                  {label}
                                </span>
                              </li>
                            )
                          )}
                        </ul>
                      </section>
                      <hr />
                      <section className="resume-skills-section resume-section">
                        <h3 className="resume-section-heading text-uppercase ">
                          <i className="resume-section-heading-icon me-2">
                            <FontAwesomeIcon icon={faPuzzlePiece} />
                          </i>
                          Area of Expertise
                        </h3>
                        <ul className="list-inline">
                          {practitionerProfile.area_expertise
                            .filter(
                              (item) =>
                                item.label !== "Other (please specify in bio)"
                            )
                            .map(({ label, value }) => (
                              <li className="list-inline-item">
                                <span className="badge resume-skill-badge">
                                  {label}
                                </span>
                              </li>
                            ))}
                        </ul>
                      </section>
                      <hr />
                      <section className="resume-skills-section resume-section">
                        <h3 className="resume-section-heading text-uppercase ">
                          <i className="resume-section-heading-icon me-2">
                            <FontAwesomeIcon icon={faPeopleRoof} />
                          </i>
                          Ages Seen
                        </h3>
                        <ul className="list-inline">
                          {practitionerProfile.ages_seen.map(
                            ({ label, value }) => (
                              <li className="list-inline-item">
                                <span className="badge resume-skill-badge">
                                  {label}
                                </span>
                              </li>
                            )
                          )}
                        </ul>
                      </section>
                      <hr />
                      <section className="resume-skills-section resume-section">
                        <h3 className="resume-section-heading text-uppercase">
                          <i className="resume-section-heading-icon me-2">
                            <FontAwesomeIcon icon={faMicrophoneLines} />
                          </i>
                          Languages Spoken
                        </h3>
                        <ul className="list-inline">
                          {practitionerProfile.languagespoken
                            .filter(
                              (item) =>
                                item.label !== "Other (please specify in bio)"
                            )
                            .map(({ label, value }) => (
                              <li className="list-inline-item">
                                <span className="badge resume-skill-badge">
                                  {label}
                                </span>
                              </li>
                            ))}
                        </ul>
                      </section>
                      <hr />
                      <section className="resume-skills-section resume-section">
                        <h3 className="resume-section-heading text-uppercase ">
                          <i className="resume-section-heading-icon me-2">
                            <FontAwesomeIcon icon={faSuitcaseMedical} />
                          </i>
                          Counseling Format
                        </h3>
                        <ul className="list-inline">
                          {practitionerProfile.counselingformat
                            .filter(
                              (item) =>
                                item.label !== "Other (please specify in bio)"
                            )
                            .map(({ label, value }) => (
                              <li className="list-inline-item">
                                <span className="badge resume-skill-badge">
                                  {label}
                                </span>
                              </li>
                            ))}
                        </ul>
                      </section>
                      <hr />
                      <section className="resume-skills-section resume-section">
                        <h3 className="resume-section-heading text-uppercase ">
                          <i className="resume-section-heading-icon me-2">
                            <FontAwesomeIcon icon={faToriiGate} />
                          </i>
                          religious Affiliation
                        </h3>
                        <ul className="list-inline">
                          {practitionerProfile.religiousAffiliation
                            .filter(
                              (item) =>
                                item.label !== "Other (please specify in bio)"
                            )
                            .map(({ label, value }) => (
                              <li className="list-inline-item">
                                <span className="badge resume-skill-badge">
                                  {label}
                                </span>
                              </li>
                            ))}
                        </ul>
                      </section>
                      <hr />
                      <section className="resume-skills-section resume-section">
                        <h3 className="resume-section-heading text-uppercase">
                          <i className="resume-section-heading-icon me-2">
                            <FontAwesomeIcon icon={faSpa} />
                          </i>
                          therapeutic Approaches
                        </h3>
                        <ul className="list-inline">
                          {practitionerProfile.therapeuticApproaches.map(
                            ({ label, value }) => (
                              <li className="list-inline-item">
                                <span className="badge resume-skill-badge">
                                  {label}
                                </span>
                              </li>
                            )
                          )}
                        </ul>
                      </section>
                      <hr />
                      <section className="resume-skills-section resume-section">
                        <h3 className="resume-section-heading text-uppercase ">
                          <i className="resume-section-heading-icon me-2">
                            <FontAwesomeIcon icon={faAward} />
                          </i>
                          License & Degree
                        </h3>
                        <ul className="list-inline">
                          {practitionerProfile.license_degree.map(
                            ({ label, value }) => (
                              <li className="list-inline-item">
                                <span className="badge resume-skill-badge">
                                  {label}
                                </span>
                              </li>
                            )
                          )}
                        </ul>
                      </section>
                    </div>
                    <hr />
                  </div>

                  {/* <div className="">
                    <div className="row">
                      <div className="col-sm-4">
                       
                      </div>
                      <div className="col-sm-4">
                       
                      </div>
                      <div className="col-sm-4">
                      
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Enter Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isOTPSending ? (
            <LoadingSpinner info="Sending verification code.."></LoadingSpinner>
          ) : (
            <Form>
              <Form.Group controlId="formBasicName">
                <Form.Label>Your Name</Form.Label>
                <Form.Control
                  type="text"
                  name="fullName"
                  placeholder="Enter your name"
                  value={contactData.fullName}
                  onChange={handleContactChange}
                />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Your Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Enter your email"
                  value={contactData.email}
                  onChange={handleContactChange}
                />{" "}
                {!otpSent && (
                  <Button variant="secondary" onClick={handleSendOTP}>
                    Send Verification Code
                  </Button>
                )}
                {otpSent && (
                  <Alert variant="success">
                    Code sent! Please check your email and spam folder, then
                    enter the code here.
                  </Alert>
                )}
                {otpSendError && (
                  <Alert variant="danger">
                    Failed to send Code. Please try again.
                  </Alert>
                )}
                {/* <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text> */}
              </Form.Group>
              {otpSent && (
                <Form.Group controlId="formBasicOTP">
                  <Form.Label>Verification Code</Form.Label>
                  <Form.Control
                    type="text"
                    name="otp"
                    placeholder="Enter OTP"
                    value={contactData.otp}
                    onChange={handleContactChange}
                  />
                </Form.Group>
              )}
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          {isContactSubmit ? (
            <LoadingSpinner info="Submitting request.."></LoadingSpinner>
          ) : (
            <>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleContactSubmit}>
                Submit
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default PractitionerProfile;
