import Footer from "src/components/common/footer/Footer";
import Header from "src/components/header/Header";
import corousel1 from "src/assets/images/clients/1.png";
import corousel2 from "src/assets/images/clients/2.png";
import corousel3 from "src/assets/images/clients/3.png";
import corousel4 from "src/assets/images/clients/4.png";
import corousel5 from "src/assets/images/clients/5.png";
import corousel6 from "src/assets/images/clients/6.png";
import corousel7 from "src/assets/images/clients/7.png";
import corousel8 from "src/assets/images/clients/8.png";
import corousel9 from "src/assets/images/clients/9.png";
import corousel10 from "src/assets/images/clients/10.png";
import corousel11 from "src/assets/images/clients/11.png";
import corousel12 from "src/assets/images/clients/12.png";
import imagebannerurl from "src/assets//images/Certified_Practitioner_Directory_Reg.png";
import avatar1 from "src/assets/images/avatars/1.png";
import avatar2 from "src/assets/images/avatars/2.png";
import avatar3 from "src/assets/images/avatars/3.png";
import avatar4 from "src/assets/images/avatars/4.png";
import avatar5 from "src/assets/images/avatars/5.png";
import avatar6 from "src/assets/images/avatars/6.png";
import avatar7 from "src/assets/images/avatars/7.png";
import avatar8 from "src/assets/images/avatars/8.png";
import avatar9 from "src/assets/images/avatars/9.png";
import avatar10 from "src/assets/images/avatars/10.png";
import avatar11 from "src/assets/images/avatars/11.png";
import avatar12 from "src/assets/images/avatars/12.png";
import avatar13 from "src/assets/images/avatars/13.png";
import avatar14 from "src/assets/images/avatars/14.png";
import avatar15 from "src/assets/images/avatars/15.png";
import avatar16 from "src/assets/images/avatars/16.png";
import avatar17 from "src/assets/images/avatars/17.png";
import avatar18 from "src/assets/images/avatars/18.png";
import avatar19 from "src/assets/images/avatars/19.png";
import avatar20 from "src/assets/images/avatars/20.png";
import avatar21 from "src/assets/images/avatars/21.png";
import submittedImage from "src/assets/images/employee-submit.gif";
import { useState, useRef, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAPIURL } from "src/API/apiService";
import Select from "react-select";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, Button, Row, Col } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
import {
  faFacebook,
  faXTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { faGlobe, faCamera } from "@fortawesome/free-solid-svg-icons";
import ImageBanner from "src/components/common/ImageBanner";
import PractitionerProfile from "./PractitionerProfile";
import LoadingSpinner from "src/components/common/LoadingSpinner";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DOMPurify from "dompurify";
import AvatarEditor from "react-avatar-editor";
import { Box, Slider } from "@mui/material";
import { ImageCompressor } from "image-compressor";
const boxStyle = {
  width: "300px",
  height: "300px",
  display: "flex",
  flexFlow: "column",
  justifyContent: "center",
  alignItems: "center",
};
const modalStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export default function PractitionerRegister() {
  const navigate = useNavigate();
  const profileImageRef = useRef(null);
  const [isLoading, setisLoading] = useState(false);
  const [showAvatars, seshowAvatars] = useState(false);
  const [currentCustomer, setcurrentCustomer] = useState([]);
  const [slideValue, setSlideValue] = useState(10);
  const cropRef = useRef(null);

  const [formData, setFormData] = useState({
    counselorId: 0,
    firstname: "",
    lastname: "",
    showPhone: false,
    showEmail: false,
    showAddress: true,
    phone: "",
    address: "",
    email: "",
    stateprovince: "",
    zip: "",
    country: "",
    ages_seen: [],
    area_expertise: [],
    languagespoken: [],
    counselingformat: [],
    therapeuticApproaches: [],
    counselorFormat: [],
    license_degree: [],
    aboutmebio: "",
    feesInsurance: "",
    religiousAffiliation: [],
    otherexpertise: "",
    experience: "",
    profileimage: null,
    profileimageString: "",
    clientfocus: [],
    twitterURL: "",
    linkedinURL: "",
    facebookURL: "",
    websiteURL: "",
    // email: "",
    // password: "",
  });

  const [errors, setErrors] = useState({});
  const [practitionerId, setpractitionerId] = useState(0);
  const [lgShow, setLgShow] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [countdown, setCountdown] = useState(15);
  const [licenses, setLicenses] = useState([]);
  const { REACT_APP_PractitionerRegiter, REACT_APP_CounselorLicenses } =
    process.env;
  const [src, setSrc] = useState(null);

  // preview
  const [preview, setPreview] = useState(null);

  // modal state
  const [modalOpen, setModalOpen] = useState(false);

  const imageCompressor = new ImageCompressor();

  const compressorSettings = {
    toWidth: 300,
    mimeType: "image/png",
    mode: "strict",
    quality: 0.6,
    speed: "low",
  };
  const compressorSettingsLow = {
    toWidth: 300,
    mimeType: "image/png",
    mode: "strict",
    quality: 0.8,
    speed: "low",
  };
  const compressorSettingsmoreLow = {
    toWidth: 500,
    mimeType: "image/png",
    mode: "strict",
    quality: 1,
    speed: "High",
  };
  // handle Click
  const handleInputClick = (e) => {
    e.preventDefault();
    profileImageRef.current.click();
  };
  // handle Change
  const handleImgChange = (e) => {
    debugger;

    try {
      const fileSize = e.target.files[0].size;
      if (fileSize < 2 * 1024 * 1024) {
        imageCompressor.run(
          URL.createObjectURL(e.target.files[0]),
          compressorSettingsmoreLow,
          proceedCompressedImage
        );
      } else if (fileSize < 4 * 1024 * 1024) {
        imageCompressor.run(
          URL.createObjectURL(e.target.files[0]),
          compressorSettingsLow,
          proceedCompressedImage
        );
      } else {
        imageCompressor.run(
          URL.createObjectURL(e.target.files[0]),
          compressorSettings,
          proceedCompressedImage
        );
      }
    } catch (error) {
      console.error("Failed to compress profile Image:", error);
      setSrc(URL.createObjectURL(e.target.files[0]));

      setModalOpen(true);
    }
  };
  function proceedCompressedImage(compressedSrc) {
    // console.log("in callback comprese", compressedSrc);
    setSrc(compressedSrc);

    setModalOpen(true);
  }
  const validateUrl = (inputUrl) => {
    const isValidUrlFormat =
      /^(https:\/\/|www\.)[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+(?:\/.*)?$/.test(
        inputUrl
      );

    return isValidUrlFormat;
  };

  const handleEdit = () => {
    // Your register logic here
    console.log("Registering...");
    setLgShow(false);
  };
  function GetCounselorLicenses() {
    // debugger;
    try {
      var licenseURL = getAPIURL(REACT_APP_CounselorLicenses);
      fetch(licenseURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: null,
      })
        .then((response) => {
          if (response.ok) {
          } else {
            console.error("Failed to load countries");
          }
          return response.json();
        })
        .then((result) => {})
        .catch((error) => {
          // Handle errors
          console.error("Failed to load countries:", error);
        });
    } catch (error) {
      console.error("Failed to load countries:", error);
    }
  }
  const handleSuccessClose = () => {
    // Your register logic here

    setShowSuccess(false);
  };
  const count = 21;
  const avatarPaths = [
    avatar1,
    avatar2,
    avatar3,
    avatar4,
    avatar5,
    avatar6,
    avatar7,
    avatar8,
    avatar9,
    avatar10,
    avatar11,
    avatar12,
    avatar13,
    avatar14,
    avatar15,
    avatar16,
    avatar17,
    avatar18,
    avatar19,
    avatar20,
    avatar21,
  ];

  const chooseAvatar = (avatar) => {
    profileImageRef.current.value = "";
    let defaultImage = avatar;
    // if (value == 1) {
    //   defaultImage = avatar1;
    // } else {
    //   defaultImage = avatar1;
    // }
    fetch(defaultImage)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();

        reader.onload = () => {
          // When FileReader finishes reading the file, the result will be the base64 string
          const base64 = reader.result;
          setFormData({
            ...formData,
            profileimage: blob,
            profileimageString: base64,
          });
        };

        // Read the image file as a data URL (base64)
        reader.readAsDataURL(blob);
      })
      .catch((error) => {
        console.error("Error fetching default image:", error);
      });
    seshowAvatars(false);
  };
  const roleOptions = [
    { value: "admin", label: "Admin" },
    { value: "user", label: "User" },
  ];
  const countryOptions = [
    { value: "1", label: "India" },
    { value: "2", label: "US" },
    { value: "3", label: "Japan" },
  ];
  const counselingFormatOptions = [
    { value: "inPerson", label: "In-Person" },
    { value: "onlineVideo", label: "Online (Video)" },
    { value: "phone", label: "Phone" },
    { value: "textChat", label: "Text/Chat" },
    { value: "groupCounseling", label: "Group Counseling" },
    { value: "Other", label: "Other (please specify in bio)" },
  ];
  const religiousAffiliationOptions = [
    { value: "Non-denominational", label: "Non-denominational" },
    { value: "Secular", label: "Secular" },
    { value: "Agnostic", label: "Agnostic" },
    { value: "Apostolic", label: "Apostolic" },
    { value: "AssemblyofGod", label: "Assembly of God" },
    { value: "Baha'i", label: "Baha’i" },
    { value: "Baptist", label: "Baptist" },
    { value: "Buddhist", label: "Buddhist" },
    { value: "Catholic", label: "Catholic" },
    { value: "Christian", label: "Christian" },
    { value: "ChristianReform", label: "Christian Reform" },
    { value: "ChristianScience", label: "Christian Science" },
    { value: "ChurchofChrist", label: "Church of Christ" },
    { value: "ChurchofGod", label: "Church of God" },
    { value: "ChurchoftheBrethren", label: "Church of the Brethren" },
    { value: "ChurchoftheNazarene", label: "Church of the Nazarene" },
    { value: "Congregational", label: "Congregational" },
    { value: "DisciplesofChrist", label: "Disciples of Christ" },
    { value: "Episcopalian", label: "Episcopalian" },
    { value: "Evangelical", label: "Evangelical" },
    { value: "FoursquareGospel", label: "Foursquare Gospel" },
    { value: "FullGospel", label: "Full Gospel" },
    { value: "Fundamentalist", label: "Fundamentalist" },
    { value: "Hindu", label: "Hindu" },
    { value: "Holiness", label: "Holiness" },
    {
      value: "IndependentChristianChurch",
      label: "Independent Christian Church",
    },
    { value: "Interfaith", label: "Interfaith" },
    { value: "Jehovah'sWitness", label: "Jehovah’s Witness" },
    { value: "Jewish", label: "Jewish" },
    { value: "Lutheran", label: "Lutheran" },
    { value: "Mennonite", label: "Mennonite" },
    { value: "Methodist", label: "Methodist" },
    { value: "Muslim", label: "Muslim" },
    { value: "Mormon", label: "Mormon" },
    { value: "Nazarene", label: "Nazarene" },
    { value: "OpenBible", label: "Open Bible" },
    { value: "OrthodoxChristian", label: "Orthodox Christian" },
    { value: "Pentacostal", label: "Pentacostal" },
    { value: "Presbyterian", label: "Presbyterian" },
    { value: "Protestant", label: "Protestant" },
    { value: "Quaker", label: "Quaker" },
    { value: "Reformed", label: "Reformed" },
    { value: "SalvationArmy", label: "Salvation Army" },
    { value: "Seventh-DayAdventist", label: "Seventh-Day Adventist" },
    { value: "Sikh", label: "Sikh" },
    { value: "UnitarianUniversalist", label: "Unitarian Universalist" },
    { value: "UnitedChurchofChrist", label: "United Church of Christ" },
    { value: "Other", label: "Other (please specify in bio)" },
  ];
  const languageOptions = [
    { value: "english", label: "English" },
    { value: "spanish", label: "Spanish" },
    { value: "mandarin", label: "Chinese (Mandarin)" },
    { value: "korean", label: "Korean" },
    { value: "indonesian", label: "Indonesian" },
    { value: "french", label: "French" },
    { value: "german", label: "German" },
    { value: "portuguese", label: "Portuguese" },
    { value: "Other", label: "Other (please specify in bio)" },
  ];
  const ageGroupOptions = [
    { value: "children", label: "Children" },
    { value: "preteen", label: "Preteen" },
    { value: "teen", label: "Teen" },
    { value: "generalAdults", label: "General Adults" },
    { value: "seniorAdults", label: "Senior Adults" },
  ];
  const areaExpertiseOptions = [
    { value: "Addiction", label: "Addiction" },
    { value: "ADHD", label: "ADHD" },
    { value: "Adoption", label: "Adoption" },
    { value: "AlcoholUse", label: "Alcohol Use" },
    { value: "AngerManagement", label: "Anger Management" },
    { value: "Anxiety", label: "Anxiety" },
    { value: "AspergersSyndrome", label: "Asperger’s Syndrome" },
    { value: "Autism", label: "Autism" },
    { value: "BehavioralIssues", label: "Behavioral Issues" },
    { value: "BipolarDisorder", label: "Bipolar Disorder" },
    { value: "BorderlinePersonality", label: "Borderline Personality (BPD)" },
    { value: "CareerCounseling", label: "Career Counseling" },
    { value: "ChildsCounseling", label: "Child’s Counseling" },
    { value: "ChronicIllness", label: "Chronic Illness" },
    { value: "ChronicPain", label: "Chronic Pain" },
    { value: "Codependency", label: "Codependency" },
    { value: "CouplesCounseling", label: "Couples’ Counseling" },
    { value: "Depression", label: "Depression" },
    { value: "Divorce", label: "Divorce" },
    { value: "DomesticAbuse", label: "Domestic Abuse" },
    { value: "DrugAbuse", label: "Drug Abuse" },
    { value: "DualDiagnosis", label: "Dual Diagnosis" },
    { value: "EatingDisorders", label: "Eating Disorders" },
    { value: "Gambling", label: "Gambling" },
    { value: "GriefBereavement", label: "Grief/Bereavement" },
    { value: "Hoarding", label: "Hoarding" },
    { value: "Infertility", label: "Infertility" },
    { value: "Infidelity", label: "Infidelity" },
    { value: "IntellectualDisability", label: "Intellectual Disability" },
    { value: "InternetAddiction", label: "Internet Addiction" },
    { value: "LifeCoaching", label: "Life Coaching" },
    { value: "MarriageFamilyCounseling", label: "Marriage/Family Counseling" },
    { value: "MedicalDetox", label: "Medical Detox" },
    { value: "MedicationManagement", label: "Medication Management" },
    { value: "MensIssues", label: "Men’s Issues" },
    { value: "Obesity", label: "Obesity" },
    { value: "ObsessiveCompulsive", label: "Obsessive-Compulsive (OCD)" },
    { value: "Parenting", label: "Parenting" },
    { value: "Pregnancy", label: "Pregnancy" },
    { value: "PremaritalCounseling", label: "Premarital Counseling" },
    { value: "Prenatal", label: "Prenatal" },
    { value: "Postpartum", label: "Postpartum" },
    {
      value: "PostTraumaticStressDisorder",
      label: "Post Traumatic Stress Disorder",
    },
    { value: "RacialIdentity", label: "Racial Identity" },
    { value: "SelfEsteem", label: "Self Esteem" },
    { value: "SelfHarming", label: "Self-Harming" },
    { value: "SexTherapy", label: "Sex Therapy" },
    { value: "SexualAbuse", label: "Sexual Abuse" },
    { value: "SexualAddiction", label: "Sexual Addiction" },
    { value: "SleeporInsomnia", label: "Sleep or Insomnia" },
    { value: "Spirituality", label: "Spirituality" },
    { value: "SportsPerformance", label: "Sports Performance" },
    { value: "Stress", label: "Stress" },
    { value: "SubstanceUse", label: "Substance Use" },
    { value: "SuicidalIdeation", label: "Suicidal Ideation" },
    { value: "TestingandEvaluation", label: "Testing and Evaluation" },
    { value: "Transgender", label: "Transgender" },
    { value: "TraumaandPTSD", label: "Trauma and PTSD" },
    { value: "TraumaticBrainInjury", label: "Traumatic Brain Injury" },
    { value: "VideoGameAddiction", label: "Video Game Addiction" },
    { value: "WeightLoss", label: "Weight Loss" },
    { value: "WomensIssues", label: "Women’s Issues" },
    { value: "Other", label: "Other (please specify in bio)" },
  ];
  const targetAudienceOptions = [
    { value: "individuals", label: "Individuals" },
    { value: "couples", label: "Couples" },
    { value: "families", label: "Families" },
    { value: "groups", label: "Groups" },
  ];
  const counselorFormatOptions = [
    { value: "therapist", label: "Therapist" },
    { value: "pastor", label: "Pastor" },
    { value: "psychologist", label: "Psychologist" },
    { value: "psychiatrist", label: "Psychiatrist" },
    { value: "counselor", label: "Counselor" },
    { value: "socialWorker", label: "Social Worker" },
    {
      value: "marriageFamilyCounselor",
      label: "Marriage and Family Counselor",
    },
    { value: "schoolCounselor", label: "School Counselor" },
    { value: "careerCounselor", label: "Career Counselor" },
    { value: "mentalHealthCounselor", label: "Mental Health Counselor" },
    { value: "humanResources", label: "Human Resources" },
    { value: "industrialPsychologist", label: "Industrial Psychologist" },
    { value: "spiritualCounselor", label: "Spiritual Counselor" },
    { value: "Other", label: "Other (please specify in bio)" },
  ];
  const therapyApproachOptions = [
    { value: "attachmentBased", label: "Attachment-based" },
    {
      value: "christianFaithBasedCounseling",
      label: "Christian/Faith-based Counseling",
    },
    { value: "coaching", label: "Coaching" },
    { value: "couplesCounseling", label: "Couples Counseling" },
    { value: "cognitiveBehavioralCBT", label: "Cognitive Behavioral (CBT)" },
    { value: "culturallySensitive", label: "Culturally Sensitive" },
    { value: "dialecticalBehaviorDBT", label: "Dialectical Behavior (DBT)" },
    { value: "emdr", label: "EMDR" },
    { value: "familyTherapy", label: "Family Therapy" },
    { value: "individualTherapy", label: "Individual Therapy" },
    {
      value: "internalFamilySystemsIFS",
      label: "Internal Family Systems (IFS)",
    },
    { value: "mindfulnessBasedMBCT", label: "Mindfulness-Based (MBCT)" },
    { value: "playTherapy", label: "Play Therapy" },
    { value: "psychodynamic", label: "Psychodynamic" },
    { value: "traumaFocused", label: "Trauma Focused" },
  ];
  // const defaultCountryValue = countryOptions[0]; // Choose the option you want to pre-select
  // const defaultLicenseValues = [countryOptions[0], countryOptions[2]]; // Choose the options you want to pre-select

  const handleclientfocusChange = (clientfocus) => {
    setFormData({
      ...formData,
      clientfocus,
    });
  };
  const handleages_seenChange = (ages_seen) => {
    setFormData({
      ...formData,
      ages_seen,
    });
  };
  const handlearea_expertiseChange = (area_expertise) => {
    setFormData({
      ...formData,
      area_expertise,
    });
  };
  const handlelanguagespokenChange = (languagespoken) => {
    setFormData({
      ...formData,
      languagespoken,
    });
  };
  const handlecounselorFormatChange = (counselorFormat) => {
    setFormData({
      ...formData,
      counselorFormat,
    });
  };
  const handlecounselingformatChange = (counselingformat) => {
    setFormData({
      ...formData,
      counselingformat,
    });
  };
  const handletherapyApproachChange = (therapeuticApproaches) => {
    setFormData({
      ...formData,
      therapeuticApproaches,
    });
  };
  const handleOccupationChange = (Occupation) => {
    setFormData({
      ...formData,
      Occupation,
    });
  };
  const handleReligiousAffiliationChange = (religiousAffiliation) => {
    setFormData({
      ...formData,
      religiousAffiliation,
    });
  };
  ClassicEditor.defaultConfig = {
    toolbar: {
      items: [
        "heading",
        "|",
        "bold",
        "italic",
        "|",
        "bulletedList",
        "numberedList",
        "|",
        "insertTable",
        "|",
        "|",
        "|",
        "undo",
        "redo",
      ],
    },
    image: {
      toolbar: [
        "imageStyle:full",
        "imageStyle:side",
        "|",
        "imageTextAlternative",
      ],
    },
    table: {
      contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
    },
    language: "en",
  };
  useEffect(() => {
    // GetCounselorLicenses();

    //  setLicenses(licnseoptions);
    window.scrollTo(0, 0);
    const sessioncustomer = sessionStorage.getItem("customer");
    if (sessioncustomer) {
      const customer = JSON.parse(sessioncustomer);
      setcurrentCustomer(customer);
      const licnseselected =
        (customer.CustomerLicenses &&
          customer.CustomerLicenses.split(",").map((license) => ({
            value: license,
            label: license,
          }))) ||
        [];
      console.log(licnseselected);
      setLicenses(licnseselected);
      setFormData({
        ...formData,
        firstname: customer.Firstname,
        lastname: customer.Lastname,
        country: customer.Country,
        license_degree: licnseselected,
        counselorId: customer.Id,
      });
    } else {
      navigate("/Practitioner/PractitionerDirectory");
    }
  }, []);
  useEffect(() => {
    let timer;
    if (showSuccess && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [showSuccess, countdown]);

  useEffect(() => {
    if (showSuccess && countdown === 0) {
      // Redirect logic here
      window.location.href = "/Practitioner/PractitionerDirectory"; // Redirect to other page
    }
  }, [showSuccess, countdown]);

  const notify = (message, IsSuccess) => {
    IsSuccess
      ? toast.success(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined, // or customize the progress bar
          //  onClick: () => alert("Notification clicked!"),
          onClose: () => console.log("Notification closed!"),
        })
      : toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined, // or customize the progress bar
        });
  };
  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;

    if (name === "profileimage") {
      const imageFile = files[0];
      const reader = new FileReader();
      debugger;
      reader.onload = () => {
        // When FileReader finishes reading the file, the result will be the base64 string
        const base64 = reader.result;
        setFormData({
          ...formData,
          profileimage: imageFile,
          profileimageString: base64,
        });
      };

      // Read the image file as a data URL (base64)
      reader.readAsDataURL(imageFile);
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };
  const validateForm = () => {
    debugger;
    let newErrors = {};

    if (!formData.firstname) {
      newErrors.firstname = "firstname is required";
    }
    if (!formData.lastname) {
      newErrors.lastname = "lastname is required";
    }
    if (!formData.address) {
      newErrors.address = "street address is required";
    }
    if (!formData.phone) {
      newErrors.phone = "Contact phone is required";
    }
    if (!formData.email) {
      newErrors.email = "Contact email is required";
    }
    if (!formData.stateprovince) {
      newErrors.stateprovince = "state province is required";
    }
    if (!formData.country) {
      newErrors.country = "country is required";
    }
    if (!formData.aboutmebio) {
      newErrors.aboutmebio = "About me / bio is required";
    }
    // if (!formData.experience) {
    //   newErrors.experience = "Experience is required";
    // }
    // if (!formData.feesInsurance) {
    //   newErrors.feesInsurance = "fees Insurance is required";
    // }
    // debugger;
    //console.log(formData.profileimage.size);
    if (!formData.profileimage) {
      newErrors.profileimage = "Profile image is required";
    }
    if (
      formData.profileimage &&
      formData.profileimage.size !== undefined &&
      formData.profileimage.size > 10 * 1024 * 1024
    ) {
      newErrors.profileimage = "Image size must be less than 10MB";
    }
    if (formData.clientfocus.length === 0) {
      newErrors.clientfocus = "Select at least one client focus option";
    }
    if (formData.ages_seen.length === 0) {
      newErrors.ages_seen = "Select at least one cages seen option";
    }
    if (formData.counselorFormat.length === 0) {
      newErrors.counselorFormat = "Select at least one counselor format option";
    }
    if (formData.area_expertise.length === 0) {
      newErrors.area_expertise = "Select at least one area of expertise option";
    }
    if (formData.languagespoken.length === 0) {
      newErrors.languagespoken = "Select at least one language spoken";
    }
    if (formData.counselingformat.length === 0) {
      newErrors.counselingformat =
        "Select at least one counseling format option";
    }
    if (formData.therapeuticApproaches.length === 0) {
      newErrors.therapeuticApproaches =
        "Select at least one therapeutic approaches";
    }
    if (formData.facebookURL != "" && !validateUrl(formData.facebookURL)) {
      newErrors.facebookURL = "Add a valid & secure url.";
    }

    if (formData.websiteURL != "" && !validateUrl(formData.websiteURL)) {
      newErrors.websiteURL = "Add a valid & secure url.";
    }
    if (formData.linkedinURL != "" && !validateUrl(formData.linkedinURL)) {
      newErrors.linkedinURL = "Add a valid & secure url.";
    }
    if (formData.twitterURL != "" && !validateUrl(formData.twitterURL)) {
      newErrors.twitterURL = "Add a valid & secure url.";
    }
    // if (!formData.license_degree) {
    //   newErrors.license_degree = "License/Degree Type is required";
    // }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };
  const ImageUpload = () => {
    const [image, setImage] = useState(null);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        formData.profileimage = file;
      };
      reader.readAsDataURL(file);
    }
  };

  const handlePreview = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLgShow(true);
      // console.log(formData);
    } else {
      notify(
        "Some required fields are empty. Please check the form and complete the necessary information. Fields marked with an asterisk (*) are mandatory.",
        false
      );
      console.log("Form has errors. Please fix them.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setisLoading(true);
      //  console.log(formData);
      try {
        var submitURL = getAPIURL(REACT_APP_PractitionerRegiter);
        const response = await fetch(submitURL, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${currentCustomer.AuthToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });

        if (response.ok) {
          setisLoading(false);
          const result = await response.json();
          debugger;
          if (result.data === "Success") {
            console.log("Form data submitted successfully!");
            notify(
              "Thank you for updating your directory listing. Please allow up to two business day for our team to review your submission and update your listing",
              true
            );
            setLgShow(false);
            setShowSuccess(true);
          } else {
            console.error("Failed to submit form data");
            setisLoading(false);
            notify(
              "Failed to submit your data for approval. Try again after sometime or contact PPI.",
              false
            );
          }
        } else {
          console.error("Failed to submit form data");
          setisLoading(false);
          notify(
            "Failed to submit your data for approval. Try again after sometime or contact PPI.",
            false
          );
        }
      } catch (error) {
        console.error("Error submitting form data:", error);
        setisLoading(false);
        notify(
          "Something went wrong! Try again after sometime or contact PPI.",
          false
        );
      }
      //  console.log("Form submitted:", formData);
    } else {
      notify(
        "Some required fields are empty. Please check the form and complete the necessary information. Fields marked with an asterisk (*) are mandatory.",
        false
      );
      console.log("Form has errors. Please fix them.");
    }
  };
  const handleImageSave = async () => {
    if (cropRef) {
      const dataUrl = cropRef.current.getImage().toDataURL();
      // const result = await fetch(dataUrl);
      // const blob = await result.blob();

      //  setPreview(URL.createObjectURL(blob));

      fetch(dataUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const reader = new FileReader();

          reader.onload = () => {
            // When FileReader finishes reading the file, the result will be the base64 string
            const base64 = reader.result;
            setFormData({
              ...formData,
              profileimage: blob,
              profileimageString: base64,
            });
          };

          // Read the image file as a data URL (base64)
          reader.readAsDataURL(blob);
        })
        .catch((error) => {
          console.error("Error cropping the image:", error);
        });
      setModalOpen(false);
    }
  };
  return (
    <>
      <Header></Header>
      <section
        id="slider"
        className="slider-parallax swiper_wrapper full-screen clearfix"
      >
        <div className="slider-parallax-inner">
          <div className="swiper-container swiper-parent">
            <div className="swiper-wrapper">
              <div
                className="swiper-slide dark practioner-register-banner"
                style={{}}
              >
                <div className="container clearfix">
                  {/* <div className="slider-caption slider-caption-center">
									<h2 data-caption-animate="fadeInUp">Welcome to T-JTA</h2>
									<p data-caption-animate="fadeInUp" data-caption-delay="200">The Taylor-Johnson Temperament Analysis®(T-JTA®) is a widely used personality assessment for individual, pre-marital, marital and family counseling.</p>
								</div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <ImageBanner
        heading="" //"Certified Practitioner Directory: Registration"
        subheading="" //"Help clients find you and make a meaningful impact with your personalized public listing. Showcase your expertise, share your qualifications, and connect with those seeking your counseling services. "
        imageUrl={imagebannerurl}
        imageHeight="400px" // Adjust the height as needed
        imageWidth="100%" // Adjust the width as needed
        backgroundSize="100% 25rem"
      /> */}
      {/* Content
		=============================================  */}
      <section id="content">
        <div className="content-wrap">
          <div className="container clearfix">
            {/* Postcontent
					=============================================  */}
            <div className=" nobottommargin">
              <h4>
                Enter your contact information and personal details as you wish
                them to appear on your directory listing. This information will
                be publicly visible to individuals searching for T-JTA Certified
                Practitioners.{" "}
              </h4>

              <div className="contact-widget">
                <div className="contact-form-result"></div>

                <form className="nobottommargin" onSubmit={handlePreview}>
                  <div className="form-process"></div>
                  <div className="col_one_third">
                    <label>
                      First Name <small>*</small>
                    </label>
                    <input
                      type="text"
                      name="firstname"
                      value={formData.firstname}
                      onChange={handleChange}
                      readOnly="true"
                      className="required sm-form-control"
                    />
                    {errors.firstname && (
                      <p className="errormsg">{errors.firstname}</p>
                    )}
                  </div>
                  <div className="col_one_third">
                    <label>
                      Last Name <small>*</small>
                    </label>
                    <input
                      type="text"
                      name="lastname"
                      value={formData.lastname}
                      onChange={handleChange}
                      readOnly="true"
                      className="required sm-form-control"
                    />
                    {errors.lastname && (
                      <p className="errormsg">{errors.lastname}</p>
                    )}
                  </div>
                  <div className="col_one_third col_last">
                    <label>
                      Street Address <small>*</small>
                    </label>
                    <input
                      type="text"
                      name="address"
                      value={formData.address}
                      onChange={handleChange}
                      className="required sm-form-control"
                    />
                    {errors.address && (
                      <p className="errormsg">{errors.address}</p>
                    )}
                  </div>
                  <div className="clear"></div>
                  <div className="col_one_third">
                    <label>
                      State/Province <small>*</small>
                    </label>
                    <input
                      type="text"
                      name="stateprovince"
                      value={formData.stateprovince}
                      onChange={handleChange}
                      className="required sm-form-control"
                    />
                    {errors.stateprovince && (
                      <p className="errormsg">{errors.stateprovince}</p>
                    )}
                  </div>
                  <div className="col_one_third ">
                    <label>Zip</label>
                    <input
                      type="text"
                      name="zip"
                      value={formData.zip}
                      onChange={handleChange}
                      className="sm-form-control"
                    />
                    {errors.zip && <p className="errormsg">{errors.zip}</p>}
                  </div>
                  <div className="col_one_third col_last">
                    <label>Country</label> <small>*</small>
                    {/* <Select
                      className="required basic-single"
                      classNamePrefix="select"
                    //  defaultValue={defaultCountryValue}
                      isDisabled={true}
                      value={countryOptions.find(
                        (option) => option.value === formData.country
                      )}
                      onChange={(selectedOption) =>
                        setFormData({
                          ...formData,
                          country: selectedOption.value,
                        })
                      }
                      name="country"
                      options=
                      {countryOptions}
                    /> */}
                    <input
                      type="text"
                      name="country"
                      value={formData.country}
                      readOnly="true"
                      //  onChange={handleChange}
                      className="sm-form-control"
                    />
                    {errors.country && (
                      <p className="errormsg">{errors.country}</p>
                    )}
                  </div>
                  <div className="clear"></div>
                  <div className="col_one_third">
                    <label>
                      Contact Phone <small>*</small>
                    </label>
                    <input
                      type="text"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      className="required sm-form-control"
                    />
                    {errors.phone && <p className="errormsg">{errors.phone}</p>}
                  </div>
                  <div className="col_one_third">
                    <label>
                      Contact Email <small>*</small>
                    </label>
                    <input
                      type="text"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="required sm-form-control"
                    />
                    {errors.email && <p className="errormsg">{errors.email}</p>}
                  </div>
                  <div className="col_one_third col_last">
                    <label>Website:</label>
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <FontAwesomeIcon icon={faGlobe} />
                        </span>
                      </div>
                      <input
                        type="text"
                        name="websiteURL"
                        value={formData.websiteURL}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Enter Website URL"
                      />
                      {errors.websiteURL && (
                        <p className="errormsg">{errors.websiteURL}</p>
                      )}
                    </div>
                  </div>
                  <div className="clear"></div>
                  <div className="col_one_third">
                    <label>Facebook:</label>
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <FontAwesomeIcon icon={faFacebook} />
                        </span>
                      </div>
                      <input
                        type="text"
                        name="facebookURL"
                        value={formData.facebookURL}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Enter Facebook URL"
                      />
                    </div>
                    {errors.facebookURL && (
                      <p className="errormsg">{errors.facebookURL}</p>
                    )}
                    <p>Entered URL: {formData.facebookURL}</p>{" "}
                    {/* URL is automatically escaped by React */}
                  </div>
                  <div className="col_one_third">
                    <label>LinkedIn:</label>
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <FontAwesomeIcon icon={faLinkedin} />
                        </span>
                      </div>
                      <input
                        type="text"
                        name="linkedinURL"
                        value={formData.linkedinURL}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Enter LinkedIn URL"
                      />
                      {errors.linkedinURL && (
                        <p className="errormsg">{errors.linkedinURL}</p>
                      )}
                    </div>
                  </div>
                  <div className="col_one_third col_last">
                    <label>X (Twitter):</label>
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <FontAwesomeIcon icon={faXTwitter} />
                        </span>
                      </div>
                      <input
                        type="text"
                        name="twitterURL"
                        value={formData.twitterURL}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Enter Twitter URL"
                      />
                      {errors.twitterURL && (
                        <p className="errormsg">{errors.twitterURL}</p>
                      )}
                    </div>
                  </div>
                  <div className="clear"></div>
                  <div className="col_one_third">
                    <label>
                      Counselor Type <small>*</small>{" "}
                    </label>
                    <CreatableSelect
                      isMulti
                      name="counselorFormat"
                      options={counselorFormatOptions}
                      value={formData.counselorFormat}
                      onChange={handlecounselorFormatChange}
                      className="required"
                      placeholder="Select all the roles that apply to you "
                    />

                    {errors.counselorFormat && (
                      <p className="errormsg">{errors.counselorFormat}</p>
                    )}
                  </div>
                  <div className="col_one_third">
                    <label>
                      Client focus <small>*</small>
                    </label>
                    <Select
                      isMulti
                      name="clientfocus"
                      options={targetAudienceOptions}
                      value={formData.clientfocus}
                      // value={
                      //   (formData.clientfocus &&
                      //     formData.clientfocus.split(",").map((option) => ({
                      //       value: option,
                      //       label: option,
                      //     }))) ||
                      //   []
                      // } // Check for null or empty string before splitting
                      onChange={handleclientfocusChange}
                      className="required"
                      placeholder="Select the type(s) of clients you work with "
                    />

                    {errors.clientfocus && (
                      <p className="errormsg">{errors.clientfocus}</p>
                    )}
                  </div>{" "}
                  <div className="col_one_third col_last">
                    <label>
                      Areas of Expertise <small>*</small>{" "}
                    </label>
                    <Select
                      isMulti
                      name="area_expertise"
                      options={areaExpertiseOptions}
                      value={formData.area_expertise}
                      onChange={handlearea_expertiseChange}
                      className="required"
                      placeholder="Indicate your top specialties (up to five) "
                    />

                    {errors.area_expertise && (
                      <p className="errormsg">{errors.area_expertise}</p>
                    )}
                  </div>
                  <div className="clear"></div>
                  <div className="col_one_third">
                    <label>
                      Ages Seen <small>*</small>
                    </label>
                    <Select
                      isMulti
                      name="ages_seen"
                      options={ageGroupOptions}
                      value={formData.ages_seen}
                      onChange={handleages_seenChange}
                      className="required"
                      placeholder="Select the age(s) you work with"
                    />

                    {errors.ages_seen && (
                      <p className="errormsg">{errors.ages_seen}</p>
                    )}
                  </div>
                  <div className="col_one_third">
                    <label>
                      Languages Spoken <small>*</small>{" "}
                    </label>
                    <CreatableSelect
                      isMulti
                      name="languagespoken"
                      options={languageOptions}
                      value={formData.languagespoken}
                      onChange={handlelanguagespokenChange}
                      className="required"
                      placeholder="Select the languages you speak fluently"
                    />

                    {errors.languagespoken && (
                      <p className="errormsg">{errors.languagespoken}</p>
                    )}
                  </div>
                  <div className="col_one_third col_last">
                    <label>
                      Counseling Format <small>*</small>
                    </label>
                    <CreatableSelect
                      isMulti
                      name="counselingformat"
                      options={counselingFormatOptions}
                      value={formData.counselingformat}
                      onChange={handlecounselingformatChange}
                      className="required"
                      placeholder="Select your available counseling format(s)"
                    />

                    {errors.counselingformat && (
                      <p className="errormsg">{errors.counselingformat}</p>
                    )}
                  </div>
                  <div className="clear"></div>
                  <div className="col_one_third">
                    <label>Religious Affiliation</label>
                    <Select
                      isMulti
                      name="religiousAffiliation"
                      options={religiousAffiliationOptions}
                      value={formData.religiousAffiliation}
                      onChange={handleReligiousAffiliationChange}
                      className="required"
                      placeholder="Indicate your religious affiliation or lack thereof "
                    />

                    {errors.religiousAffiliation && (
                      <p className="errormsg">{errors.religiousAffiliation}</p>
                    )}
                  </div>
                  <div className="col_one_third">
                    <label>
                      Therapeutic Approaches <small>*</small>
                    </label>
                    <CreatableSelect
                      isMulti
                      name="therapeuticApproaches"
                      options={therapyApproachOptions}
                      value={formData.therapeuticApproaches}
                      onChange={handletherapyApproachChange}
                      className="required"
                      placeholder="Indicate your counseling orientation(s)"
                    />

                    {errors.therapeuticApproaches && (
                      <p className="errormsg">{errors.therapeuticApproaches}</p>
                    )}
                  </div>
                  <div className="col_one_third col_last">
                    <label>
                      License/Degree Type <small>*</small>
                    </label>
                    <Select
                      isMulti
                      name="license_degree"
                      options={licenses}
                      value={formData.license_degree}
                      //   defaultValue={defaultLicenseValues}
                      onChange={handleOccupationChange}
                      isDisabled={true}
                    />

                    {errors.Occupation && (
                      <p className="errormsg">{errors.Occupation}</p>
                    )}
                  </div>
                  <div className="clear"></div>
                  <div className="col_half">
                    <label>
                      About Me/Bio <small>*</small>{" "}
                    </label>
                    <CKEditor
                      editor={ClassicEditor}
                      data="<p><br/></p><p>In your own words, write a brief biography or introduction about yourself. You might showcase your professional background, counseling philosophy, and approach. </p><p><br/></p>"
                      onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        //  console.log("Editor is ready to use!", editor);
                      }}
                      onChange={(event, editor) => {
                        // Sanitize the HTML content
                        const sanitizedData = DOMPurify.sanitize(
                          editor.getData()
                        );
                        const encodedData = encodeURIComponent(sanitizedData);
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          aboutmebio: encodedData,
                        }));
                        // console.log(encodedData);
                      }}
                      onBlur={(event, editor) => {
                        // console.log("Blur.", editor);
                      }}
                      onFocus={(event, editor) => {
                        // console.log("Focus.", editor.getData());
                      }}
                    />
                    {/* <textarea
                      className="required sm-form-control"
                      rows="7"
                      cols="30"
                      name="aboutmebio"
                      value={formData.aboutmebio}
                      onChange={handleChange}
                      placeholder="In your own words, write a brief biography or introduction about yourself. You might showcase your professional background, counseling philosophy, and approach. "
                    ></textarea> */}
                    {errors.aboutmebio && (
                      <p className="errormsg">{errors.aboutmebio}</p>
                    )}
                  </div>
                  <div className="col_half col_last">
                    <label>Experience </label>
                    <CKEditor
                      editor={ClassicEditor}
                      data="<p><br/></p><p>Detail your counseling experience, previous positions held, and any noteworthy accomplishments in your career. </p><p><br/></p>"
                      onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        //  console.log("Editor is ready to use!", editor);
                      }}
                      onChange={(event, editor) => {
                        // Sanitize the HTML content
                        const sanitizedData = DOMPurify.sanitize(
                          editor.getData()
                        );
                        const encodedData = encodeURIComponent(sanitizedData);
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          experience: encodedData,
                        }));
                        //  console.log(encodedData);
                      }}
                      onBlur={(event, editor) => {
                        //console.log("Blur.", editor);
                      }}
                      onFocus={(event, editor) => {
                        // console.log("Focus.", editor.getData());
                      }}
                    />
                    {/* <textarea
                      className="required sm-form-control"
                      rows="7"
                      cols="30"
                      name="experience"
                      value={formData.experience}
                      onChange={handleChange}
                      placeholder="Detail your counseling experience, previous positions held, and any noteworthy accomplishments in your career."
                    ></textarea> */}
                    {errors.experience && (
                      <p className="errormsg">{errors.experience}</p>
                    )}
                  </div>
                  <div className="clear"></div>
                  <div className="col_half">
                    <label>Fees & Insurance</label>
                    <CKEditor
                      editor={ClassicEditor}
                      data="<p><br/></p><p>If desired, share your counseling fees, payment options, and insurance acceptance, if applicable. </p><p><br/></p>"
                      onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        //   console.log("Editor is ready to use!", editor);
                      }}
                      onChange={(event, editor) => {
                        // Sanitize the HTML content
                        const sanitizedData = DOMPurify.sanitize(
                          editor.getData()
                        );
                        const encodedData = encodeURIComponent(sanitizedData);
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          feesInsurance: encodedData,
                        }));
                        // console.log(encodedData);
                      }}
                      onBlur={(event, editor) => {
                        //  console.log("Blur.", editor);
                      }}
                      onFocus={(event, editor) => {
                        //  console.log("Focus.", editor.getData());
                      }}
                    />
                    {/* <textarea
                      className="required sm-form-control"
                      rows="7"
                      cols="30"
                      name="feesInsurance"
                      value={formData.feesInsurance}
                      onChange={handleChange}
                      placeholder=" If desired, share your counseling fees, payment options, and insurance acceptance, if applicable."
                    ></textarea> */}
                    {errors.feesInsurance && (
                      <p className="errormsg">{errors.feesInsurance}</p>
                    )}
                  </div>
                  <div className="col_one_fourth">
                    <label>Upload Profile Image</label>
                    <div className="mb-3">
                      <input
                        type="file"
                        name="profileimage"
                        id="profileimage"
                        accept="image/*"
                        onChange={handleImgChange}
                        className="sm-form-control"
                        ref={profileImageRef}
                      ></input>

                      {errors.profileimage && (
                        <p className="errormsg">{errors.profileimage}</p>
                      )}
                      <small>Upload an image (max size: 10MB)</small>
                    </div>
                    <label>
                      or{" "}
                      <span
                        className="text-decoration-underline cursor-pointer"
                        onClick={() => seshowAvatars(true)}
                      >
                        {" "}
                        click here
                      </span>{" "}
                      to choose avatar
                    </label>
                  </div>
                  <div className="col_one_fourth col_last">
                    {formData.profileimage && (
                      <div>
                        <h5>Preview:</h5>
                        <img
                          src={URL.createObjectURL(formData.profileimage) || ""}
                          alt="Preview"
                          className="img-fluid"
                          style={{ width: "50%" }}
                        />
                      </div>
                    )}
                  </div>{" "}
                  <div className="clear"></div>
                  <div className="col_one_third  text-center mt-4 pt-2">
                    <Form.Check
                      type="switch"
                      id="togglePhone"
                      label="Show My Contact Phone on profile"
                      checked={formData.showPhone}
                      onChange={handleChange}
                      name="showPhone"
                    />
                  </div>
                  <div className="col_one_third text-center mt-4 pt-2">
                    <Form.Check
                      type="switch"
                      id="toggleEmail"
                      label="Show My Contact Email on profile"
                      checked={formData.showEmail}
                      onChange={handleChange}
                      name="showEmail"
                    />
                  </div>
                  <div className="col_one_third col_last text-center mt-4 pt-2">
                    <Form.Check
                      type="switch"
                      id="toggleAddress"
                      label="Show My Street Address on profile"
                      checked={formData.showAddress}
                      onChange={handleChange}
                      name="showAddress"
                    />
                  </div>
                  <div className="col_full">
                    <button
                      className="button button-3d nomargin"
                      type="submit"
                      value="submit"
                    >
                      Preview Details
                    </button>
                  </div>
                  <div className="clear"></div>
                </form>
              </div>
            </div>
            {/* .postcontent end  */}
          </div>

          {/* <div className="container clearfix">
            <div className="col_one_third">
              <div className="feature-box fbox-effect">
                <div className="fbox-icon">
                  <a href="#">
                    <i className="icon-screen i-alt"></i>
                  </a>
                </div>
                <h3>Responsive Layout</h3>
                <p>
                  Powerful Layout with Responsive functionality that can be
                  adapted to any screen size. Resize browser to view.
                </p>
              </div>
            </div>

            <div className="col_one_third">
              <div className="feature-box fbox-effect">
                <div className="fbox-icon">
                  <a href="#">
                    <i className="icon-eye i-alt"></i>
                  </a>
                </div>
                <h3>Retina Ready Graphics</h3>
                <p>
                  Looks beautiful &amp; ultra-sharp on Retina Screen Displays.
                  Retina Icons, Fonts &amp; all others graphics are optimized.
                </p>
              </div>
            </div>

            <div className="col_one_third col_last">
              <div className="feature-box fbox-effect">
                <div className="fbox-icon">
                  <a href="#">
                    <i className="icon-beaker i-alt"></i>
                  </a>
                </div>
                <h3>Powerful Performance</h3>
                <p>
                  tjta includes tons of optimized code that are completely
                  customizable and deliver unmatched fast performance.
                </p>
              </div>
            </div>

            <div className="clear"></div>

            <div className="col_one_third nobottommargin">
              <div className="feature-box fbox-effect">
                <div className="fbox-icon">
                  <a href="#">
                    <i className="icon-stack i-alt"></i>
                  </a>
                </div>
                <h3>Premium Sliders Included</h3>
                <p>
                  tjta included 20+ custom designed Slider Pages with Premium
                  Sliders like Layer, Revolution, Swiper &amp; others.
                </p>
              </div>
            </div>

            <div className="col_one_third nobottommargin">
              <div className="feature-box fbox-effect">
                <div className="fbox-icon">
                  <a href="#">
                    <i className="icon-tint i-alt"></i>
                  </a>
                </div>
                <h3>Unlimited Color Options</h3>
                <p>
                  Change the color scheme of the Theme in a flash just by
                  changing the 6-digit HEX code in the colors.php file.
                </p>
              </div>
            </div>

            <div className="col_one_third nobottommargin col_last">
              <div className="feature-box fbox-effect">
                <div className="fbox-icon">
                  <a href="#">
                    <i className="icon-text-width i-alt"></i>
                  </a>
                </div>
                <h3>CUSTOMIZABLE FONTS</h3>
                <p>
                  Use any Font you like from Google Web Fonts, Typekit or other
                  Web Fonts. They will blend in perfectly.
                </p>
              </div>
            </div>

            <div className="clear"></div>
            <div className="line"></div>

            <div
              id="oc-clients-full"
              className="owl-carousel image-carousel carousel-widget"
              data-margin="30"
              data-nav="false"
              data-loop="true"
              data-autoplay="5000"
              data-pagi="false"
              data-items-xxs="2"
              data-items-xs="3"
              data-items-sm="4"
              data-items-md="5"
              data-items-lg="6"
            >
              <a href="#">
                <img src={corousel1} alt="Clients" />
              </a>
              <a href="#">
                <img src={corousel2} alt="Clients" />
              </a>
              <a href="#">
                <img src={corousel3} alt="Clients" />
              </a>
              <a href="#">
                <img src={corousel4} alt="Clients" />
              </a>
              <a href="#">
                <img src={corousel5} alt="Clients" />
              </a>
              <a href="#">
                <img src={corousel6} alt="Clients" />
              </a>
              <a href="#">
                <img src={corousel7} alt="Clients" />
              </a>
              <a href="#">
                <img src={corousel8} alt="Clients" />
              </a>
              <a href="#">
                <img src={corousel9} alt="Clients" />
              </a>
              <a href="#">
                <img src={corousel10} alt="Clients" />
              </a>
              <a href="#">
                <img src={corousel11} alt="Clients" />
              </a>
              <a href="#">
                <img src={corousel12} alt="Clients" />
              </a>
            </div>
          </div> */}
          <ToastContainer />
        </div>
      </section>
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading ? (
            <LoadingSpinner info="Submitting your profile for approval.."></LoadingSpinner>
          ) : (
            <PractitionerProfile practitionerProfile={formData} />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleEdit}>
            Edit
          </Button>

          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showSuccess}
        onHide={() => {}}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton={false}>
          <Modal.Title>Submission Successful</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center">
            <Col xs="auto">
              <img
                src={submittedImage}
                alt="Success Icon"
                style={{ maxWidth: "100px", maxHeight: "100px" }}
              />
            </Col>
          </Row>
          <p className="text-center">
            Your details have been successfully submitted.
          </p>
          <p className="text-center">
            We will notify you once your profile is publically listed.
          </p>
          {showSuccess && (
            <p className="text-center">
              You will be redirected to another page in {countdown} seconds.
            </p>
          )}
        </Modal.Body>
      </Modal>
      <Modal
        show={modalOpen}
        onHide={(e) => setModalOpen(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton={true}>
          <Modal.Title>Image Editor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center">
            <Col xs="auto">
              <Box sx={boxStyle}>
                <AvatarEditor
                  ref={cropRef}
                  image={src}
                  style={{ width: "100%", height: "100%" }}
                  border={50}
                  borderRadius={150}
                  color={[0, 0, 0, 0.72]}
                  scale={slideValue / 10}
                  rotate={0}
                />

                {/* MUI Slider */}
                <Slider
                  min={10}
                  max={50}
                  sx={{
                    margin: "0 auto",
                    width: "80%",
                    color: "cyan",
                  }}
                  size="medium"
                  defaultValue={slideValue}
                  value={slideValue}
                  onChange={(e) => setSlideValue(e.target.value)}
                />
              </Box>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleImageSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showAvatars}
        onHide={() => seshowAvatars(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        scrollable={true}
      >
        <Modal.Header closeButton={true}>
          <Modal.Title>Choose avatars</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center">
            <div className="">
              {avatarPaths.map((avatar, index) => (
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{ margin: "0.5rem" }}
                  onClick={() => chooseAvatar(avatar)}
                >
                  <img
                    key={index}
                    src={avatar}
                    alt="1"
                    style={{ maxWidth: "70px", "max-height": "70px" }}
                  />
                </button>
              ))}
            </div>
          </Row>
        </Modal.Body>
      </Modal>
      {/* #content end  */}
      <Footer></Footer>
    </>
  );
}
