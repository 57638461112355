import { toast } from "react-toastify";

const { REACT_APP_APIHOSTURL } = process.env;
const { REACT_APP_TrainingLinkLOGIN, REACT_APP_CommonLinkLOGIN } = process.env;

export const getAPIURL = (endpoint) => {
  let apiURL = REACT_APP_APIHOSTURL;
  //console.log(apiURL);

  let URL = "" + apiURL + endpoint;
  // console.log(URL);
  return URL;
};

export const handleTrainingLinkLogin = async (value, type) => {
  let results;
  debugger;
  console.log("entered loginapi");
  let body = {
    type: type,
    userToken: value,
    raisedFrom: 0,
  };
  let Loginurl = getAPIURL(REACT_APP_TrainingLinkLOGIN);
  console.log(Loginurl);
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  };
  let req = new Request(Loginurl, requestOptions);
  await fetch(req)
    .then((res) => res.json())
    .then((result) => {
      if (result.Status === 200 && result.Message === "Success") {
        toast.success("Success Notification !", {
          position: toast.POSITION.TOP_CENTER,
        });
        //  userdetail= result.Data;
        results = true;
      } else {
        toast.error(result.messsage, {
          position: toast.POSITION.TOP_CENTER,
        });
        results = false;
      }
    });

  return results;
};
export const handleCommonLinkLogin = async (value, type) => {
  let results = null;
  debugger;
  console.log("entered loginapi");
  let body = {
    type: type,
    userToken: value,
    raisedFrom: 0,
  };
  let Loginurl = getAPIURL(REACT_APP_CommonLinkLOGIN);
  console.log(Loginurl);
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  };
  let req = new Request(Loginurl, requestOptions);
  await fetch(req)
    .then((res) => res.json())
    .then((result) => {
      if (result.Status === 200 && result.Message === "Success") {
        toast.success("Success Notification !", {
          position: toast.POSITION.TOP_CENTER,
        });
        results = result;
        //  results = true;
      } else {
        toast.error(result.messsage, {
          position: toast.POSITION.TOP_CENTER,
        });
        results = result;
      }
    });

  return results;
};
