import * as React from "react";

import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import favicon from "src/assets/images/favicon.ico";
import CountUp from "react-countup";
export default function Footer() {
  const { REACT_APP_TJTAURL } = process.env;
  return (
    <>
      {/* Footer
		=============================================  */}
      <footer id="footer" className="dark">
        <div className="container">
          {/* Footer Widgets
				=============================================  */}
          <div className="footer-widgets-wrap clearfix">
            <div className="col_two_third">
              <div className="col_one_third">
                <div className="widget clearfix">
                  <img src={favicon} alt="" className="footer-logo" />

                  <p>
                    We believe in <strong>helping people</strong> better
                    understand themselves and each other.
                  </p>

                  <div className="footer-address">
                    <address>
                      <strong>Headquarters:</strong>
                      <br />
                      Psychological Publications, Inc.
                      <br />
                      PO Box 3577
                      <br />
                      Thousand Oaks, CA 91362
                      <br />
                    </address>
                    <abbr title="Phone Number">
                      <strong>Phone:</strong>
                    </abbr>{" "}
                    (800) 345-TEST <br />
                    <abbr title="Email Address">
                      <strong>Email:</strong>
                    </abbr>{" "}
                    info@ijta.com <br />{" "}
                    <a href={REACT_APP_TJTAURL + "contactus"}>
                      {" "}
                      <abbr title="Contact Us">
                        <strong> More information…</strong>
                      </abbr>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col_one_third">
                <div className="widget widget_links clearfix">
                  <h4>Information </h4>

                  <ul>
                    <li>
                      <a href={REACT_APP_TJTAURL + "About-Us"}>About Us </a>
                    </li>
                    <li>
                      <a href={REACT_APP_TJTAURL + "privacy-policy"}>
                        Privacy Policy
                      </a>
                    </li>
                    <li>
                      <a href={REACT_APP_TJTAURL + "legal-policies"}>
                        Legal Policies{" "}
                      </a>
                    </li>
                    <li>
                      <a href={REACT_APP_TJTAURL + "shipping-returns"}>
                        Shipping & Returns{" "}
                      </a>
                    </li>
                    <li>
                      <a
                        href={
                          REACT_APP_TJTAURL +
                          "terms-and-conditions-of-sale-and-use-of-psychological-publications-inc-products"
                        }
                      >
                        Terms and Conditions{" "}
                      </a>
                    </li>
                    <li>
                      <a href={REACT_APP_TJTAURL + "website-terms-of-use"}>
                        Terms of Use
                      </a>
                    </li>
                    <li>
                      <a
                        href={REACT_APP_TJTAURL + "frequently-asked-questions"}
                      >
                        Frequently Asked Questions
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col_one_third col_last">
                <div className="widget clearfix">
                  <h4>Recent Posts</h4>

                  <div id="post-list-footer">
                    <div className="spost clearfix">
                      <div className="entry-c">
                        <div className="entry-title">
                          <h4>
                            <a href="https://blog885546128.wordpress.com/2022/04/29/__tjtaselfcare/">
                              T-JTA Tips to Avoid Counselor Burnout
                            </a>
                          </h4>
                        </div>
                        <ul className="entry-meta">
                          <li>29th April 2022</li>
                        </ul>
                      </div>
                    </div>

                    {/* <div className="spost clearfix">
                      <div className="entry-c">
                        <div className="entry-title">
                          <h4>
                            <a href="#">
                              Elit Assumenda vel amet dolorum quasi
                            </a>
                          </h4>
                        </div>
                        <ul className="entry-meta">
                          <li>10th July 2014</li>
                        </ul>
                      </div>
                    </div>

                    <div className="spost clearfix">
                      <div className="entry-c">
                        <div className="entry-title">
                          <h4>
                            <a href="#">
                              Debitis nihil placeat, illum est nisi
                            </a>
                          </h4>
                        </div>
                        <ul className="entry-meta">
                          <li>10th July 2014</li>
                        </ul>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="col_one_third col_last">
              <div
                className="widget clearfix"
                style={{ marginBottom: "-20px" }}
              >
                <div className="row">
                  <div className="col-md-6 bottommargin-sm">
                    <div className="counter counter-small">
                      <CountUp start={1} end={50} />
                      K+
                    </div>
                    <h5 className="nobottommargin">
                      T-JTA Authorized Practitioners
                    </h5>
                  </div>

                  <div className="col-md-6 bottommargin-sm">
                    <div className="counter counter-small">
                      <CountUp start={0} end={2} />
                      M+
                    </div>
                    <h5 className="nobottommargin">
                      T-JTA Assessments Served{" "}
                    </h5>
                  </div>
                </div>
              </div>

              {/* <div className="widget subscribe-widget clearfix">
                <h5>
                  <strong>Subscribe</strong> to Our Newsletter to get Important
                  News, Training Offers &amp; Inside Scoops:
                </h5>
                <div className="widget-subscribe-form-result"></div>
                <form
                  id="widget-subscribe-form"
                  action="include/subscribe.php"
                  role="form"
                  method="post"
                  className="nobottommargin"
                >
                  <div className="input-group divcenter">
                    <span className="input-group-addon">
                      <i className="icon-email2"></i>
                    </span>
                    <input
                      type="email"
                      id="widget-subscribe-form-email"
                      name="widget-subscribe-form-email"
                      className="form-control required email"
                      placeholder="Enter your Email"
                    />
                    <span className="input-group-btn">
                      <button className="btn btn-success" type="submit">
                        Subscribe
                      </button>
                    </span>
                  </div>
                </form>
              </div> */}

              <div
                className="widget clearfix"
                style={{ marginBottom: "-20px" }}
              >
                <div className="row">
                  <div className="col-md-6 clearfix bottommargin-sm">
                    <a
                      href="https://www.facebook.com/people/Taylor-Johnson-Temperament-Analysis-T-JTA/100067409912166/"
                      className="social-icon si-dark si-colored si-facebook nobottommargin"
                      style={{ marginRight: "10px" }}
                    >
                      <i className="icon-facebook"></i>
                      <i className="icon-facebook"></i>
                    </a>
                    <a href="https://www.facebook.com/people/Taylor-Johnson-Temperament-Analysis-T-JTA/100067409912166/">
                      <small style={{ display: "block", marginTop: "3px" }}>
                        <strong>Like us</strong>
                        <br />
                        on Facebook
                      </small>
                    </a>
                  </div>
                  <div className="col-md-6 clearfix">
                    <a
                      href="https://blog885546128.wordpress.com/"
                      className="social-icon si-dark si-colored si-rss nobottommargin"
                      style={{ marginRight: "10px" }}
                    >
                      <i className="icon-rss"></i>
                      <i className="icon-rss"></i>
                    </a>
                    <a href="https://blog885546128.wordpress.com/">
                      <small style={{ display: "block", marginTop: "3px" }}>
                        <strong>Subscribe</strong>
                        <br />
                        to RSS Feeds
                      </small>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* .footer-widgets-wrap end  */}
        </div>

        {/* Copyrights
			=============================================  */}
        <div id="copyrights">
          <div className="container clearfix">
            <div className="col_half">
              Copyright &copy; 2024 All Rights Reserved by Psychological
              Publications, Inc.
              <br />
              <div className="copyright-links">
                <a href={REACT_APP_TJTAURL + "website-terms-of-use"}>
                  Terms of Use
                </a>{" "}
                /{" "}
                <a href={REACT_APP_TJTAURL + "privacy-policy"}>
                  Privacy Policy
                </a>
              </div>
            </div>

            <div className="col_half col_last tright">
              <div className="fright clearfix">
                <a
                  href="https://www.facebook.com/people/Taylor-Johnson-Temperament-Analysis-T-JTA/100067409912166/"
                  className="social-icon si-small si-borderless si-facebook"
                >
                  <i className="icon-facebook"></i>
                  <i className="icon-facebook"></i>
                </a>

                <a
                  href="#"
                  className="social-icon si-small si-borderless si-twitter"
                >
                  <i className="icon-twitter"></i>
                  <i className="icon-twitter"></i>
                </a>

                <a
                  href="#"
                  className="social-icon si-small si-borderless si-gplus"
                >
                  <i className="icon-gplus"></i>
                  <i className="icon-gplus"></i>
                </a>

                <a
                  href="#"
                  className="social-icon si-small si-borderless si-linkedin"
                >
                  <i className="icon-linkedin"></i>
                  <i className="icon-linkedin"></i>
                </a>
              </div>
              <div className="clear"></div>
              <i className="icon-envelope2"></i> info@tjta.com{" "}
              <span className="middot">&middot;</span>{" "}
              <i className="icon-headphones"></i> (800) 345-TEST{" "}
              <span className="middot">&middot;</span>{" "}
              <i className="icon-skype2"></i> /psychpub
            </div>
          </div>
        </div>
        {/* #copyrights end  */}
      </footer>
      {/* #footer end  */}
      {/* External JavaScripts
	=============================================  */}

      {/* Footer Scripts
	=============================================  */}
    </>
  );
}
