import logo from "./logo.svg";
import "src/assets/sassStyles/_global.scss";
import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import TrainerDirectory from "./components/pages/trainerDirectory";
import LinkLogin from "./components/pages/LinkLogin";
import ProtectedRoute from "./ProtectedRoute/ProtectedRoute";
import { useState } from "react";
import PractitionerDirectory from "./components/pages/Practitioner/PractitionerDirectory";
import PractitionerRegister from "./components/pages/Practitioner/PractitionerRegister";
export default function App() {
  const [user, setUser] = useState(null);
  const { REACT_APP_TJTAURL } = process.env;
  const navigate = useNavigate();
  useEffect(() => {
    if (window.location.pathname === "/Practitioner/index.html") {
      // Redirect the user to the second website
      window.location.href = REACT_APP_TJTAURL;
    }
  }, [navigate]);

  useEffect(() => {
    // Check if not in development mode and not HTTPS
    if (
      process.env.NODE_ENV === "production" &&
      window.location.protocol !== "https:"
    ) {
      // Redirect to the HTTPS version
      window.location.href =
        "https://" + window.location.host + window.location.pathname;
    }
  }, []);
  return (
    <>
      <Routes>
        <Route exact path="/" element={<TrainerDirectory />}></Route>
        <Route
          exact
          path="/Practitioner/PractitionerDirectory"
          element={<PractitionerDirectory />}
        ></Route>
        <Route
          exact
          path="/Practitioner/PractitionerRegister"
          element={<PractitionerRegister />}
        ></Route>
        <Route
          exact
          path="/LinkLogin/:authguid/:authtype"
          element={<LinkLogin />}
        ></Route>
        <Route
          path="analytics"
          element={
            <ProtectedRoute
              redirectPath="/home"
              isAllowed={!!user && user.permissions.includes("counselor")}
            >
              <PractitionerRegister />
            </ProtectedRoute>
          }
        />
      </Routes>
    </>
  );
}
