import "../../../assets/Directory.scss";

import Footer from "src/components/common/footer/Footer";
import Header from "src/components/header/Header";
import profileSample from "src/assets/images/profile.png";

import { ToastContainer, toast } from "react-toastify";
import { useEffect, useState } from "react";
import PractitionerProfile from "./PractitionerProfile";
import { getAPIURL } from "src/API/apiService";
import LoadingSpinner from "src/components/common/LoadingSpinner";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import PractitionerDirectoryCard from "./PractitionerDirectoryCard";
import { Modal, Button } from "react-bootstrap";
export default function PractitionerDirectory() {
  const [isLoading, setisLoading] = useState(false);
  const [practitioners, setpractitioners] = useState([]);
  const [countries, setcountries] = useState([]);
  const [isRegisterAllowed, setisRegisterAllowed] = useState(false);
  const [currentPractitioner, setcurrentPractitioner] = useState(null);
  const [currentCustomer, setcurrentCustomer] = useState([]);
  const [showGetCertified, setshowGetCertified] = useState(false);
  const {
    REACT_APP_PractitionerDirectory,
    REACT_APP_PPiCOuntryMaster,
    REACT_APP_PractitionerByCounselorId,
    REACT_APP_TJTAURL,
  } = process.env;
  const [filters, setFilters] = useState({
    country: "",
    zip: "",
    stateprovince: "",
    religiousAffiliation: null,
    clientfocus: "",
    counselorFormat: null,
    area_expertise: null,
    counselingformat: null,
  });
  // const customer = useSelector((state) => state.customer.customer);
  // const username = useSelector((state) => state.customer.username);
  const navigate = useNavigate();

  const areaExpertiseOptions = [
    { value: "", label: "All" },
    { value: "Addiction", label: "Addiction" },
    { value: "ADHD", label: "ADHD" },
    { value: "Adoption", label: "Adoption" },
    { value: "AlcoholUse", label: "Alcohol Use" },
    { value: "AngerManagement", label: "Anger Management" },
    { value: "Anxiety", label: "Anxiety" },
    { value: "AspergersSyndrome", label: "Asperger’s Syndrome" },
    { value: "Autism", label: "Autism" },
    { value: "BehavioralIssues", label: "Behavioral Issues" },
    { value: "BipolarDisorder", label: "Bipolar Disorder" },
    { value: "BorderlinePersonality", label: "Borderline Personality (BPD)" },
    { value: "CareerCounseling", label: "Career Counseling" },
    { value: "ChildsCounseling", label: "Child’s Counseling" },
    { value: "ChronicIllness", label: "Chronic Illness" },
    { value: "ChronicPain", label: "Chronic Pain" },
    { value: "Codependency", label: "Codependency" },
    { value: "CouplesCounseling", label: "Couples’ Counseling" },
    { value: "Depression", label: "Depression" },
    { value: "Divorce", label: "Divorce" },
    { value: "DomesticAbuse", label: "Domestic Abuse" },
    { value: "DrugAbuse", label: "Drug Abuse" },
    { value: "DualDiagnosis", label: "Dual Diagnosis" },
    { value: "EatingDisorders", label: "Eating Disorders" },
    { value: "Gambling", label: "Gambling" },
    { value: "GriefBereavement", label: "Grief/Bereavement" },
    { value: "Hoarding", label: "Hoarding" },
    { value: "Infertility", label: "Infertility" },
    { value: "Infidelity", label: "Infidelity" },
    { value: "IntellectualDisability", label: "Intellectual Disability" },
    { value: "InternetAddiction", label: "Internet Addiction" },
    { value: "LifeCoaching", label: "Life Coaching" },
    { value: "MarriageFamilyCounseling", label: "Marriage/Family Counseling" },
    { value: "MedicalDetox", label: "Medical Detox" },
    { value: "MedicationManagement", label: "Medication Management" },
    { value: "MensIssues", label: "Men’s Issues" },
    { value: "Obesity", label: "Obesity" },
    { value: "ObsessiveCompulsive", label: "Obsessive-Compulsive (OCD)" },
    { value: "Parenting", label: "Parenting" },
    { value: "Pregnancy", label: "Pregnancy" },
    { value: "PremaritalCounseling", label: "Premarital Counseling" },
    { value: "Prenatal", label: "Prenatal" },
    { value: "Postpartum", label: "Postpartum" },
    {
      value: "PostTraumaticStressDisorder",
      label: "Post Traumatic Stress Disorder",
    },
    { value: "RacialIdentity", label: "Racial Identity" },
    { value: "SelfEsteem", label: "Self Esteem" },
    { value: "SelfHarming", label: "Self-Harming" },
    { value: "SexTherapy", label: "Sex Therapy" },
    { value: "SexualAbuse", label: "Sexual Abuse" },
    { value: "SexualAddiction", label: "Sexual Addiction" },
    { value: "SleeporInsomnia", label: "Sleep or Insomnia" },
    { value: "Spirituality", label: "Spirituality" },
    { value: "SportsPerformance", label: "Sports Performance" },
    { value: "Stress", label: "Stress" },
    { value: "SubstanceUse", label: "Substance Use" },
    { value: "SuicidalIdeation", label: "Suicidal Ideation" },
    { value: "TestingandEvaluation", label: "Testing and Evaluation" },
    { value: "Transgender", label: "Transgender" },
    { value: "TraumaandPTSD", label: "Trauma and PTSD" },
    { value: "TraumaticBrainInjury", label: "Traumatic Brain Injury" },
    { value: "VideoGameAddiction", label: "Video Game Addiction" },
    { value: "WeightLoss", label: "Weight Loss" },
    { value: "WomensIssues", label: "Women’s Issues" },
  ];
  const counselingFormatOptions = [
    { value: "", label: "All" },
    { value: "inPerson", label: "In-Person" },
    { value: "onlineVideo", label: "Online (Video)" },
    { value: "phone", label: "Phone" },
    { value: "textChat", label: "Text/Chat" },
    { value: "groupCounseling", label: "Group Counseling" },
  ];
  const targetAudienceOptions = [
    { value: "", label: "All" },
    { value: "individuals", label: "Individuals" },
    { value: "couples", label: "Couples" },
    { value: "families", label: "Families" },
    { value: "groups", label: "Groups" },
  ];
  const counselorFormatOptions = [
    { value: "", label: "All" },
    { value: "therapist", label: "Therapist" },
    { value: "pastor", label: "Pastor" },
    { value: "psychologist", label: "Psychologist" },
    { value: "psychiatrist", label: "Psychiatrist" },
    { value: "counselor", label: "Counselor" },
    { value: "socialWorker", label: "Social Worker" },
    {
      value: "marriageFamilyCounselor",
      label: "Marriage and Family Counselor",
    },
    { value: "schoolCounselor", label: "School Counselor" },
    { value: "careerCounselor", label: "Career Counselor" },
    { value: "mentalHealthCounselor", label: "Mental Health Counselor" },
    { value: "humanResources", label: "Human Resources" },
    { value: "industrialPsychologist", label: "Industrial Psychologist" },
    { value: "spiritualCounselor", label: "Spiritual Counselor" },
  ];
  const religiousAffiliationOptions = [
    { value: "", label: "All" },
    { value: "Non-denominational", label: "Non-denominational" },
    { value: "Secular", label: "Secular" },
    { value: "Agnostic", label: "Agnostic" },
    { value: "Apostolic", label: "Apostolic" },
    { value: "AssemblyofGod", label: "Assembly of God" },
    { value: "Baha'i", label: "Baha’i" },
    { value: "Baptist", label: "Baptist" },
    { value: "Buddhist", label: "Buddhist" },
    { value: "Catholic", label: "Catholic" },
    { value: "Christian", label: "Christian" },
    { value: "ChristianReform", label: "Christian Reform" },
    { value: "ChristianScience", label: "Christian Science" },
    { value: "ChurchofChrist", label: "Church of Christ" },
    { value: "ChurchofGod", label: "Church of God" },
    { value: "ChurchoftheBrethren", label: "Church of the Brethren" },
    { value: "ChurchoftheNazarene", label: "Church of the Nazarene" },
    { value: "Congregational", label: "Congregational" },
    { value: "DisciplesofChrist", label: "Disciples of Christ" },
    { value: "Episcopalian", label: "Episcopalian" },
    { value: "Evangelical", label: "Evangelical" },
    { value: "FoursquareGospel", label: "Foursquare Gospel" },
    { value: "FullGospel", label: "Full Gospel" },
    { value: "Fundamentalist", label: "Fundamentalist" },
    { value: "Hindu", label: "Hindu" },
    { value: "Holiness", label: "Holiness" },
    {
      value: "IndependentChristianChurch",
      label: "Independent Christian Church",
    },
    { value: "Interfaith", label: "Interfaith" },
    { value: "Jehovah'sWitness", label: "Jehovah’s Witness" },
    { value: "Jewish", label: "Jewish" },
    { value: "Lutheran", label: "Lutheran" },
    { value: "Mennonite", label: "Mennonite" },
    { value: "Methodist", label: "Methodist" },
    { value: "Muslim", label: "Muslim" },
    { value: "Mormon", label: "Mormon" },
    { value: "Nazarene", label: "Nazarene" },
    { value: "OpenBible", label: "Open Bible" },
    { value: "OrthodoxChristian", label: "Orthodox Christian" },
    { value: "Pentacostal", label: "Pentacostal" },
    { value: "Presbyterian", label: "Presbyterian" },
    { value: "Protestant", label: "Protestant" },
    { value: "Quaker", label: "Quaker" },
    { value: "Reformed", label: "Reformed" },
    { value: "SalvationArmy", label: "Salvation Army" },
    { value: "Seventh-DayAdventist", label: "Seventh-Day Adventist" },
    { value: "Sikh", label: "Sikh" },
    { value: "UnitarianUniversalist", label: "Unitarian Universalist" },
    { value: "UnitedChurchofChrist", label: "United Church of Christ" },
  ];

  const notify = (message, IsSuccess) => {
    IsSuccess
      ? toast.success(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined, // or customize the progress bar
          // onClick: () => alert("Notification clicked!"),
          onClose: () => console.log("Notification closed!"),
        })
      : toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined, // or customize the progress bar
        });
  };

  function GetPractitionerProfile(customer) {
    try {
      const body = {
        counselorId: customer.Id,
      };
      var getProfileURL = getAPIURL(REACT_APP_PractitionerByCounselorId);
      fetch(getProfileURL, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${customer.AuthToken}`,
          "Content-Type": "application/json",
          // Add any other headers if required
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          if (response.ok) {
          } else {
            console.error("Failed to load countries");
          }
          return response.json();
        })
        .then((result) => {
          setcurrentPractitioner(result.data);
          console.log("practioner by counselorid", result.data);
          console.log("customerid", customer.Id);
          if (customer && customer.Id > 0 && !result.data) {
            setisRegisterAllowed(true);
          }
        })
        .catch((error) => {
          // Handle errors
          console.error("Failed to load countries:", error);
        });
    } catch (error) {
      console.error("Failed to load countries:", error);
    }
  }
  function GetPPICountries() {
    // debugger;
    try {
      var countryURL = getAPIURL(REACT_APP_PPiCOuntryMaster);
      fetch(countryURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: null,
      })
        .then((response) => {
          if (response.ok) {
          } else {
            console.error("Failed to load countries");
          }
          return response.json();
        })
        .then((result) => {
          setcountries(result.data);
        })
        .catch((error) => {
          // Handle errors
          console.error("Failed to load countries:", error);
        });
    } catch (error) {
      console.error("Failed to load countries:", error);
    }
  }
  const handleSearch = async (e) => {
    e?.preventDefault();

    setisLoading(true);
    // console.log(filters);
    try {
      var submitURL = getAPIURL(REACT_APP_PractitionerDirectory);
      fetch(submitURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(filters),
      })
        .then((response) => {
          if (response.ok) {
          } else {
            console.error("Failed to submit form data");
            setisLoading(false);
            notify(
              "Failed to submit your data for approval. Try again after sometime or contact PPI.",
              false
            );
          }
          return response.json();
        })
        .then((result) => {
          setisLoading(false);
          // console.log("customer", customer);
          var practionerslist = result.data;
          if (currentCustomer != null) {
            practionerslist = practionerslist.filter((item) => {
              // If logincustomer exists and its Id matches item.CustomerId, exclude this buyer
              return !currentCustomer.Id !== item.CustomerId;
            });
          }

          setpractitioners(practionerslist);
          if (result.data === null || result.data.length === 0) {
            notify(result.message, false);
          }
          //console.log("practitioner", result);
        })
        .catch((error) => {
          // Handle errors
          console.error("Error fetching data:", error);
          setisLoading(false);
        });
    } catch (error) {
      console.error("Error submitting form data:", error);
      setisLoading(false);
      notify(
        "Something went wrong! Try again after sometime or contact PPI.",
        false
      );
    }
  };
  useEffect(() => {
    debugger;
    if (currentCustomer) {
      console.log("yes customer");
    }
  }, [currentCustomer]);
  useEffect(() => {
    var sessioncustomer = sessionStorage.getItem("customer");
    if (sessioncustomer) {
      const customer = JSON.parse(sessioncustomer);
      GetPractitionerProfile(customer);
      setcurrentCustomer(customer);
    }
    GetPPICountries();
    handleSearch();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };
  const handleSelectChange = (selectedOption, identifier) => {
    debugger;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [identifier]: selectedOption,
    }));
  };
  const redirectToRegister = () => {
    // Replace '/otherpage' with the path to your desired page
    var hasCertifiedRole = currentCustomer.CustomerCustomerRoleMappings.filter(
      (item) => item.CustomerRole.Name === "Certified Practitioner"
    );
    // var hasCertifiedRole = currentCustomer.CustomerCustomerRoleMappings.filter(
    //   (item) => item.CustomerRole.Name === "Counselor"
    // );
    if (hasCertifiedRole?.length > 0) {
      navigate("/Practitioner/PractitionerRegister");
    } else {
      setshowGetCertified(true);
    }
  };
  const GoToCertifiedProduct = () => {
    window.location.href = REACT_APP_TJTAURL + "t-jta-certification-package";
  };
  const hideGetCertified = () => {
    setshowGetCertified(false);
  };
  return (
    <>
      <Header currentPage="practitionerdirectory"></Header>
      <section
        id="slider"
        className="slider-parallax swiper_wrapper full-screen clearfix"
      >
        <div className="slider-parallax-inner">
          <div className="swiper-container swiper-parent">
            <div className="swiper-wrapper">
              <div className="swiper-slide dark practioner-banner" style={{}}>
                <div className="container clearfix">
                  {/* <div className="slider-caption slider-caption-center">
									<h2 data-caption-animate="fadeInUp">Welcome to T-JTA</h2>
									<p data-caption-animate="fadeInUp" data-caption-delay="200">The Taylor-Johnson Temperament Analysis®(T-JTA®) is a widely used personality assessment for individual, pre-marital, marital and family counseling.</p>
								</div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Content
		=============================================  */}
      <section id="content">
        <div className="content-wrap">
          <div className="container clearfix">
            {/* Postcontent
					=============================================  */}
            <div className=" nobottommargin">
              <h3>Find a T-JTA Certified Practitoner</h3>

              <div className="contact-widget">
                <div className="contact-form-result"></div>

                <form
                  className="nobottommargin"
                  id="template-contactform"
                  name="template-contactform"
                  action="include/sendemail.php"
                  method="post"
                >
                  <div className="form-process"></div>

                  <div className="clear"></div>

                  <div className="col_one_third">
                    <label>State/Province</label>
                    <input
                      type="text"
                      name="stateprovince"
                      className="required sm-form-control"
                      value={filters.stateprovince}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col_one_third">
                    <label>Zip</label>
                    <input
                      type="text"
                      name="zip"
                      value={filters.zip}
                      onChange={handleChange}
                      className="required sm-form-control"
                    />
                  </div>

                  <div className="col_one_third col_last">
                    <label>Country</label>
                    <select
                      value={filters.country}
                      onChange={handleChange}
                      name="country"
                      className="sm-form-control"
                    >
                      <option value="">-- Select One --</option>

                      {countries?.map((country) => (
                        <option value={country.Country}>
                          {country.Country}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="clear"></div>

                  <div className="col_one_third">
                    <label for="template-contactform-subject">
                      Client focus
                    </label>
                    <Select
                      name="clientfocus"
                      options={targetAudienceOptions}
                      value={filters.clientfocus}
                      onChange={(selectedOption) =>
                        handleSelectChange(selectedOption, "clientfocus")
                      }
                      className="required"
                    />
                  </div>

                  <div className="col_one_third">
                    <label for="template-contactform-subject">
                      Counselor Type
                    </label>
                    <Select
                      name="counselorFormat"
                      options={counselorFormatOptions}
                      value={filters.counselorFormat}
                      onChange={(selectedOption) =>
                        handleSelectChange(selectedOption, "counselorFormat")
                      }
                      className="required"
                    />
                  </div>

                  <div className="col_one_third col_last">
                    <label for="template-contactform-service">
                      Area of Expertise
                    </label>
                    <Select
                      name="area_expertise"
                      options={areaExpertiseOptions}
                      value={filters.area_expertise}
                      onChange={(selectedOption) =>
                        handleSelectChange(selectedOption, "area_expertise")
                      }
                      className="required"
                    />
                  </div>
                  <div className="clear"></div>

                  <div className="col_one_third">
                    <label for="template-contactform-subject">
                      Religious Affiliation
                    </label>
                    <Select
                      name="religiousAffiliation"
                      options={religiousAffiliationOptions}
                      value={filters.religiousAffiliation}
                      onChange={(selectedOption) =>
                        handleSelectChange(
                          selectedOption,
                          "religiousAffiliation"
                        )
                      }
                      className="required"
                    />
                  </div>
                  <div className="col_one_third">
                    <label for="template-contactform-subject">
                      Counseling Format
                    </label>
                    <Select
                      name="counselingformat"
                      options={counselingFormatOptions}
                      value={filters.counselingformat}
                      onChange={(selectedOption) =>
                        handleSelectChange(selectedOption, "counselingformat")
                      }
                      className="required"
                    />
                  </div>
                  {/*     <div className="clear"></div>

                  <div className="col_one_third">
                    <label for="template-contactform-subject">
                      Counseling Format <small>*</small>
                    </label>
                    <select
                      id="template-contactform-service"
                      name="template-contactform-service"
                      className="sm-form-control"
                    >
                      <option value="">-- Select One --</option>
                      <option value="Wordpress">Wordpress</option>
                      <option value="PHP / MySQL">PHP / MySQL</option>
                      <option value="HTML5 / CSS3">HTML5 / CSS3</option>
                      <option value="Graphic Design">Graphic Design</option>
                    </select>
                  </div>

                  <div className="col_one_third">
                    <label for="template-contactform-subject">
                      Faith-based <small>*</small>
                    </label>
                    <select
                      id="template-contactform-service"
                      name="template-contactform-service"
                      className="sm-form-control"
                    >
                      <option value="">-- Select One --</option>
                      <option value="Wordpress">Wordpress</option>
                      <option value="PHP / MySQL">PHP / MySQL</option>
                      <option value="HTML5 / CSS3">HTML5 / CSS3</option>
                      <option value="Graphic Design">Graphic Design</option>
                    </select>
                  </div>

                  <div className="col_one_third col_last">
                    <label for="template-contactform-service">
                      Occupation{" "}
                    </label>
                    <select
                      id="template-contactform-service"
                      name="template-contactform-service"
                      className="sm-form-control"
                    >
                      <option value="">-- Select One --</option>
                      <option value="Wordpress">Wordpress</option>
                      <option value="PHP / MySQL">PHP / MySQL</option>
                      <option value="HTML5 / CSS3">HTML5 / CSS3</option>
                      <option value="Graphic Design">Graphic Design</option>
                    </select>
                  </div>

                  <div className="clear"></div>

                  <div className="col_one_third">
                    <label for="template-contactform-subject">
                      License/Degree Type <small>*</small>
                    </label>
                    <select
                      id="template-contactform-service"
                      name="template-contactform-service"
                      className="sm-form-control"
                    >
                      <option value="">-- Select One --</option>
                      <option value="Wordpress">Wordpress</option>
                      <option value="PHP / MySQL">PHP / MySQL</option>
                      <option value="HTML5 / CSS3">HTML5 / CSS3</option>
                      <option value="Graphic Design">Graphic Design</option>
                    </select>
                  </div>

                  <div className="col_one_third">
                    <label for="template-contactform-subject">
                      Association Affiliation <small>*</small>
                    </label>
                    <select
                      id="template-contactform-service"
                      name="template-contactform-service"
                      className="sm-form-control"
                    >
                      <option value="">-- Select One --</option>
                      <option value="Wordpress">Wordpress</option>
                      <option value="PHP / MySQL">PHP / MySQL</option>
                      <option value="HTML5 / CSS3">HTML5 / CSS3</option>
                      <option value="Graphic Design">Graphic Design</option>
                    </select>
                  </div>

                  <div className="col_one_third col_last">
                    <label for="template-contactform-service">
                      Top Specialties{" "}
                    </label>
                    <select
                      id="template-contactform-service"
                      name="template-contactform-service"
                      className="sm-form-control"
                    >
                      <option value="">-- Select One --</option>
                      <option value="Wordpress">Wordpress</option>
                      <option value="PHP / MySQL">PHP / MySQL</option>
                      <option value="HTML5 / CSS3">HTML5 / CSS3</option>
                      <option value="Graphic Design">Graphic Design</option>
                    </select>
                  </div>

                  <div className="clear"></div>

                  <div className="col_full">
                    <label for="template-contactform-message">
                      Other Expertise <small>*</small>
                    </label>
                    <textarea
                      className="required sm-form-control"
                      id="template-contactform-message"
                      name="template-contactform-message"
                      rows="6"
                      cols="30"
                    ></textarea>
                  </div>

                  <div className="col_full hidden">
                    <input
                      type="text"
                      id="template-contactform-botcheck"
                      name="template-contactform-botcheck"
                      value=""
                      className="sm-form-control"
                    />
                  </div> */}

                  <div className="col_full">
                    <button
                      className="button button-3d nomargin"
                      type="submit"
                      id="template-contactform-submit"
                      name="template-contactform-submit"
                      value="submit"
                      onClick={handleSearch}
                    >
                      Search
                    </button>
                  </div>
                </form>
              </div>
            </div>
            {/* .postcontent end  */}
          </div>
          {isLoading ? (
            <LoadingSpinner info="Getting practitioners..."></LoadingSpinner>
          ) : (
            <div className="practitioner-list">
              <div className="container mt-4 mb-4 p-3 d-flex justify-content-center">
                .
                <div className="row" style={{ width: "100%" }}>
                  {practitioners.length === 0
                    ? "No records"
                    : currentPractitioner && (
                        <PractitionerDirectoryCard
                          profile={currentPractitioner}
                          isCurrentProfile={true}
                        />
                      )}
                  {isRegisterAllowed ? (
                    <div className="col-sm-4 card-col mb-4">
                      <div className="card p-4 not-rigister">
                        <div className=" image d-flex flex-column justify-content-center align-items-center">
                          <button
                            className="card-image-btn  btn-secondary"
                            style={{
                              backgroundImage: "",
                              backgroundSize: "cover",
                              "background-repeat": "no-repeat",
                            }}
                          >
                            <img
                              src={profileSample}
                              className="card-image prac"
                            />
                          </button>
                          <span className="name mt-3">
                            {currentCustomer.Firstname +
                              " " +
                              currentCustomer.Lastname}
                          </span>

                          <div className="card__title loading-card"></div>

                          <div className=" d-flex mt-2">
                            <button
                              onClick={redirectToRegister}
                              className="btn1 btn-dark"
                            >
                              Register
                            </button>
                          </div>
                          <div className="card__description loading-card"></div>
                          <div className="card__description loading-card"></div>
                        </div>
                        <div className="card-footer">
                          <small className="text-muted font-italic">
                            Note: Your directory listing is not yet active.
                            Please register using the button above to create
                            your public profile view.
                          </small>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {practitioners.map((item) => (
                    <PractitionerDirectoryCard profile={item} />
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
      {/* < Scrollable modal  */}
      <Modal show={showGetCertified} onHide={hideGetCertified}>
        <Modal.Header closeButton>
          <Modal.Title>Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Obtain T-JTA Certification to join our public directory of T-JTA
          Certified Practitioners. Certification requires completion of the
          official T-JTA Training Seminar and T-JTA Certification Exam.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={GoToCertifiedProduct}>
            Get Certified Now
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
      <Footer></Footer>
    </>
  );
}
